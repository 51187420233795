import React from "react";
import classNames from "classnames";
import SlidingWidget from "./SlidingWidget";
import { HeaderHelpIcon, HelpDarkIcon as HelpIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import ExternalLink from "components/ExternalLink";
import MenuLinkItem from "./MenuLinkItem";
import styles from "../MainHeader.module.scss";

const MenuItem = ({ children, url }) => {
    return <ExternalLink trackCategory="HelpMenu" href={url} className={classNames(typography.body14, styles['menu-content-link-button']) } newTab>
            {children}
        </ExternalLink>
};

export default () => {
    return (
        <SlidingWidget
            widgetKey="help"
            menuTitle="Help"
            triggerClassName={styles['widget-button']}
            triggerActiveClassName={[styles['widget-button-active']]}
            MenuIcon={HelpIcon}
            triggerChildren={<HeaderHelpIcon size={24} display="block"/>}
        >
            <div className={styles['menu-content']}>
                {process.env.REACT_APP_IS_SAAS !== "true" && process.env.REACT_APP_IS_DARK !== "true" && <MenuItem label="API" url={"/occm/api-doc"}>API Documentation</MenuItem>}
                <MenuLinkItem to="/support-dashboard">Support</MenuLinkItem>
                {process.env.REACT_APP_IS_DARK !== "true" && <MenuItem url="https://docs.netapp.com/us-en/occm/index.html">Documentation</MenuItem>}
                {process.env.REACT_APP_IS_DARK !== "true" && <MenuItem url="http://community.netapp.com">Community</MenuItem>}
                {process.env.REACT_APP_IS_DARK !== "true" && <MenuItem url="https://kb.netapp.com/Cloud/BlueXP">NetApp Knowledge Base</MenuItem>}
                <MenuItem url="mailto:ng-bluexp-feedback@netapp.com">Feedback</MenuItem>
            </div>
        </SlidingWidget>
    );
};

