import React, {useRef, Suspense, useEffect} from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import {lazy} from "react-imported-component/macro";
import {CSSTransition} from "react-transition-group";
import styles from "./SlidingManagementWidget.module.scss";
import  RightPanelWidgetLoader from "components/RightPaneWidgetLoader";
import indexStyles from "index.module.scss";

const AccountManagementWidget = process.env.REACT_APP_IS_DARK === "true" ? lazy(() => import('modules/dark' /* webpackChunkName: "Dark"*/).then(r => r.ManageUsers)) : lazy(() => import('modules/accounts-management' /* webpackChunkName: "AccountManagement"*/));
const ConnectorsManagementWidget = lazy(() => import('modules/connectors' /* webpackChunkName: "Connectors" */))
const NewAddConnectorWidget = lazy(() => import('modules/add-connector' /* webpackChunkName: "AddConnector" */))

const slideClassNames = {
    enter: styles['slide-enter'],
    enterActive: styles['slide-enter-active'],
    exit: styles['slide-exit'],
    exitActive: styles['slide-exit-active']
};

export default React.memo(() => {
    const location = useLocation();
    const selectedAccountId = useSelector(state => state.tenancy.selectedAccountId)
    const containerRef = useRef(null);
    const widgetRef = useRef("div");

    let isOpen = false;
    if(!!(location.hash === "#accountManagement" && selectedAccountId)) {
        isOpen = true;
        widgetRef.current = AccountManagementWidget;
    } else if (!!(location.hash === "#connectorsManagement" && selectedAccountId)) {
        isOpen = true;
        widgetRef.current = ConnectorsManagementWidget;
    } else if (!!(location.hash?.startsWith("#addConnector") && selectedAccountId)) {
        isOpen = true;
        widgetRef.current = NewAddConnectorWidget;
    }

    useEffect(() => {
        if(isOpen) {
            document.body.classList.add(indexStyles['management-widget-open']);
        }

        return () => {
            if(isOpen) {
                document.body.classList.remove(indexStyles['management-widget-open']);
            }
        }
    }, [isOpen])

    const Widget = widgetRef.current;
    return <>
        <CSSTransition nodeRef={containerRef} in={isOpen} timeout={600} unmountOnExit classNames={slideClassNames}>
            <div ref={containerRef} className={styles.base}>
                <Suspense fallback={<RightPanelWidgetLoader/>}>
                    <Widget/>
                </Suspense>
            </div>
        </CSSTransition>
        {isOpen && <div className={styles['mask']}/>}
    </>
});
