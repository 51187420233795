import styles from "./NotificationMenu.module.scss";
import React from "react";
import { Span, StyledLink } from "@netapp/bxp-design-system-react";
import { useDispatch } from "react-redux";

const NotificationsFooter = () => {
    const dispatch = useDispatch();

    return <div className={styles['sliding-menu-footer-notification']}>
        <Span level={14}> For more information go to <StyledLink
            variant="text"
            to="/timeline"
            onClick={() => dispatch({ type: "MENU:CLOSE-WIDGET" })}> Timeline</StyledLink>
        </Span>
    </div>
}

export default NotificationsFooter;
