import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import useCurrentService from "utils/useCurrentService"
import {
    SaaSDisabled,
    NoLocalMessage,
    NeedSaaSRedirect,
    NoContainerMessage,
    UpgradeRequired,
    ComingSoonMessage,
    NoPermissionMessage,
    ServiceNeedsSaaSIsDisabled,
    ServiceNeedsThirdPartyIsDisabled,
    PullingContainerMessage
} from "./NoAccessComponents"

const ShowAngular = React.memo(() => {
    const dispatch = useDispatch();

    useEffect(() => {
        const showWrapper = () => {
            dispatch({
                type: "APP:SHOW-NG-WRAPPER"
            });
        };

        setTimeout(() => {
            showWrapper();
        }, 100);

        return () => {
            dispatch({
                type: "APP:HIDE-NG-WRAPPER"
            });
        }
    }, [dispatch]);

    return "";
});

export default ({ children, subService, featureTargets, needHeader = true }) => {
    const supportedServices = useSelector(state => state.app.supportServices);
    const { selectedAgent, selectedAccount } = useSelector(state => state.tenancy);
    const _currentService = useCurrentService();
    const currentService = (subService && { service: subService }) || _currentService;

    const needsConnector = _.get(currentService, "service.isServiceIntegration") && _.get(currentService, "service.needsConnector") && !selectedAgent;

    const supportServiceKey = _.get(currentService, "service.supportServiceKey");

    const darkSite = process.env.REACT_APP_IS_DARK === 'true';
    const infraKey = _.get(currentService, "service.agent");
    const allowedCloudProviders = _.get(currentService, "service.cloudProviders");

    let containerNotAvailable = infraKey && _.get(selectedAgent, `agent.clientInfraStatus.${infraKey}.status`, "inactive") !== "active";
    let pullingContainer = containerNotAvailable && _.isEmpty(_.get(selectedAgent,`agent.clientInfraStatus.${infraKey}`));

    if(featureTargets?.activeContainers) {
        const allActive = _.every(featureTargets.activeContainers, container => _.get(selectedAgent, `agent.clientInfraStatus.${container}.status`, "inactive") === "active");
        if(!allActive) {
            containerNotAvailable = true;
            pullingContainer = _.some(featureTargets.activeContainers, container => _.isEmpty(_.get(selectedAgent, `agent.clientInfraStatus.${container}`)))
        }
    }

    const useDockerInfra = _.get(selectedAgent, 'agent.useDockerInfra', false) || darkSite;
    const needHttpRedirect = process.env.REACT_APP_IS_SAAS !== "true" && window.location.protocol === "http:" && _.get(currentService, "service.noLocalHttp", false) && !_.get(supportedServices, "useCompliancePrivateIpContainerMode", false);
    const noLocal = process.env.REACT_APP_IS_SAAS !== "true" && _.get(currentService, "service.noLocal", false);
    const isServiceThirdParty = _.get(currentService, "service.isThirdParty", false);
    const isThirdPartyServicesEnabled = _.get(selectedAccount, "is3rdPartyServicesEnabled", false)

    const comingSoon = (allowedCloudProviders && selectedAgent) ? !allowedCloudProviders.includes(selectedAgent.provider) : false;

    const complianceTab = _.get(currentService, "service.to") === "/governance";
    const isDigitalAdvisor = currentService?.newKey === "NetApp.DigitalAdvisor";
    const isTenancyTab = currentService?.newKey === "NetApp.TenancyV4";
    const permissions = useSelector(state => state.tenancy.permissions);

    const saasIsDisabled = process.env.REACT_APP_IS_SAAS === "true" && !selectedAccount.isSaas;
    const noThirdPartyService = isServiceThirdParty && !isThirdPartyServicesEnabled;

    const isComplianceUsingPrivateIpMode = (currentService?.key === "cloud-compliance") && _.get(supportedServices, "useCompliancePrivateIpContainerMode", false);

    const serviceFunctionsInLocal = supportServiceKey && _.get(supportedServices, `servicesInformation.${supportServiceKey}.govSupported`, false);
    const serviceNeedsSaaSisDisabled = process.env.REACT_APP_IS_SAAS === "false" && infraKey && !selectedAccount.isSaas && !serviceFunctionsInLocal;

    if (saasIsDisabled) {
        return <SaaSDisabled/>
    } else if (!isComplianceUsingPrivateIpMode && serviceNeedsSaaSisDisabled) {
        return <ServiceNeedsSaaSIsDisabled currentService={currentService} needHeader={needHeader}/>
    } else if ((!permissions.allTabsAvailable && !complianceTab && !isDigitalAdvisor && !isTenancyTab)
                || (!permissions.editAccount && isTenancyTab)) {
        return <NoPermissionMessage currentService={currentService} needHeader={needHeader}/>
    } else if (noLocal) {
        return <NoLocalMessage currentService={currentService} needHeader={needHeader}/>
    } else if (needHttpRedirect && useDockerInfra) {
        return <NeedSaaSRedirect currentService={currentService} needHeader={needHeader}/>
    } else if (comingSoon) {
        return <ComingSoonMessage currentService={currentService} needHeader={needHeader}/>
    } else if (!needsConnector && infraKey && !useDockerInfra && !complianceTab && selectedAgent) {
        return <UpgradeRequired currentService={currentService} needHeader={needHeader}/>
    } else if (!needsConnector && containerNotAvailable && selectedAgent && pullingContainer) {
        return <PullingContainerMessage currentService={currentService} needHeader={needHeader}/>
    } else if (!needsConnector && containerNotAvailable && !complianceTab && selectedAgent) {
        return <NoContainerMessage currentService={currentService} needHeader={needHeader}/>
    } else if (noThirdPartyService) {
        return <ServiceNeedsThirdPartyIsDisabled currentService={currentService} needHeader={needHeader}/>
    } else {
        return children || <ShowAngular/>
    }
};
