import _ from "lodash";
import uuidv4 from "uuid/v4";

const REQUEST_HEADER = 'x-occm-use-async';
const RESPONSE_HEADER = 'x-occm-async-request-id';

export class AsyncPoller {
    clientId = uuidv4();
    unhandledRequestsMap = {};
    unhandledResponses = [];
    lastRequestId = null;
    activePollRequest = null;

    constructor(client) {
        this.client = client;

        this.requestInterceptor = client.interceptors.request.use( (config) => {
            const isAsync = !config.url.match(/\/occm\/api\/(auth|occm|audit|async)\//);

            if(isAsync) {
                config.headers[REQUEST_HEADER] = this.clientId;
            }

            return config;
        });

        client.interceptors.response.use( (response) => {
            if (response.headers[RESPONSE_HEADER]) {
                return this.matchAsyncResponse(response);
            } else {
                return response;
            }
        });
    }

    pause() {
        this.client.interceptors.request.eject(this.requestInterceptor);
    }

    safeStartPolling() {
        if (!_.isEmpty(this.unhandledRequestsMap) && !this.activePollRequest) {
            this.activePollRequest = this.client.get(`/occm/api/async/${this.clientId}?last=${this.lastRequestId}`)
                .then(({data: deferredResponses}) => {
                    if(deferredResponses.length > 0) {
                        this.lastRequestId = _.last(deferredResponses).requestId;
                    }

                    this.dispatchResponses(deferredResponses);

                    this.activePollRequest = null;
                    this.safeStartPolling();
                })
                .catch((e) => {
                    console.error(`Error polling async responses`);
                    console.log(e);

                    this.activePollRequest = null;
                    setTimeout(() => {
                        this.safeStartPolling();
                    }, 30*1000)
                })
        }
    }

    dispatchResponses(deferredResponses) {
        const unhandledResponses = this.unhandledResponses.concat(deferredResponses);

        this.unhandledResponses = _.reject(unhandledResponses, deferredResponse => {
            const unhandledRequest =  this.unhandledRequestsMap[deferredResponse.requestId];
            if(unhandledRequest) {
                delete this.unhandledRequestsMap[deferredResponse.requestId];
                this.handleResponse(unhandledRequest, deferredResponse);
                return true;
            } else {
                return false;
            }
        });
    }

    handleResponse ({asyncResponse, deferredPromise}, deferredResponse) {
        asyncResponse.status = deferredResponse.statusCode;
        asyncResponse.data = deferredResponse.response;
        if (asyncResponse.status >= 200 && asyncResponse.status <= 300) {
            deferredPromise.resolve(asyncResponse);
        } else {
            const e = new Error("API Error");
            e.response = asyncResponse;
            deferredPromise.reject(e);
        }
    };

    matchAsyncResponse (asyncResponse) {
        return new Promise((resolve, reject) => {
            const deferredPromise = { resolve, reject };
            const unhandledRequest = { asyncResponse, deferredPromise };

            const requestId = asyncResponse.headers[RESPONSE_HEADER];
            const matchedResponsesArray = _.remove(this.unhandledResponses, {requestId: requestId});

            if (matchedResponsesArray.length > 0) {
                this.handleResponse(unhandledRequest, matchedResponsesArray[0]);
            } else {
                this.unhandledRequestsMap[requestId] = unhandledRequest;
            }

            this.safeStartPolling();
        });
    };
}
