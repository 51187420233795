export default {
    'US':'US',
    'us-east-1': 'US East (N. Virginia)',
    'us-east-2': 'US East (Ohio)',
    'us-west-1': 'US West (N. California)',
    'us-west-2': 'US West (Oregon)',
    'eu-west-1': 'EU (Ireland)',
    'eu-central-1': 'EU (Frankfurt)',
    'eu-central-2': 'EU (Zurich)',
    'ap-south-1': 'Asia Pacific (Mumbai)',
    'ap-south-2': 'Asia Pacific (Hyderabad)',
    'ap-southeast-1': 'Asia Pacific (Singapore)',
    'ap-southeast-2': 'Asia Pacific (Sydney)',
    'ap-southeast-3': 'Asia Pacific (Jakarta)',
    'ap-southeast-4': 'Asia Pacific (Melbourne)',
    'ap-northeast-1': 'Asia Pacific (Tokyo)',
    'ap-northeast-2': 'Asia Pacific (Seoul)',
    'sa-east-1': 'South America (Sao Paulo)',
    'ca-central-1': 'Canada (Central)',
    'ca-west-1': 'Canada West (Calgary)',
    'eu-west-2': 'EU (London)',
    'eu-west-3': 'EU (Paris)',
    'eu-north-1': 'EU (Stockholm)',
    'us-gov-west-1': 'US West Government (N. California)',
    'us-gov-east-1': 'US East Government (N. Virginia)',
    'cn-northwest-1': 'China (Ningxia)',
    'cn-north-1': 'China (Beijing)',
    'ap-east-1': 'Asia Pacific (Hong Kong)',
    'me-south-1': 'Middle East (Bahrain)',
    'af-south-1': 'Africa (Cape Town)',
    'eu-south-1': 'EU (Milan)',
    'eu-south-2': 'EU (Spain)',
    'ap-northeast-3': 'Asia Pacific (Osaka)',
    'me-central-1': 'Middle East (UAE)',
    'il-central-1': 'Israel (Tel Aviv)'
}

export const regionsList = [
    {
        "code": "us-east-1",
        "name": "US East",
        "location": "N. Virginia",
        "label": "US East (N. Virginia)"
    },
    {
        "code": "us-east-2",
        "name": "US East",
        "location": "Ohio",
        "label": "US East (Ohio)"
    },
    {
        "code": "us-west-1",
        "name": "US West",
        "location": "N. California",
        "label": "US West (N. California)"
    },
    {
        "code": "us-west-2",
        "name": "US West",
        "location": "Oregon",
        "label": "US West (Oregon)"
    },
    {
        "code": "eu-west-1",
        "name": "EU",
        "location": "Ireland",
        "label": "EU (Ireland)"
    },
    {
        "code": "eu-central-1",
        "name": "EU",
        "location": "Frankfurt",
        "label": "EU (Frankfurt)"
    },
    {
        "code": "eu-central-2",
        "name": "EU",
        "location": "Zurich",
        "label": "EU (Zurich)"
    },
    {
        "code": "ap-south-1",
        "name": "Asia Pacific",
        "location": "Mumbai",
        "label": "Asia Pacific (Mumbai)"
    },
    {
        "code": "ap-south-2",
        "name": "Asia Pacific",
        "location": "Hyderabad",
        "label": "Asia Pacific (Hyderabad)"
    },
    {
        "code": "ap-southeast-1",
        "name": "Asia Pacific",
        "location": "Singapore",
        "label": "Asia Pacific (Singapore)"
    },
    {
        "code": "ap-southeast-2",
        "name": "Asia Pacific",
        "location": "Sydney",
        "label": "Asia Pacific (Sydney)"
    },
    {
        "code": "ap-southeast-3",
        "name": "Asia Pacific",
        "location": "Jakarta",
        "label": "Asia Pacific (Jakarta)"
    },
    {
        "code": "ap-southeast-4",
        "name": "Asia Pacific",
        "location": "Melbourne",
        "label": "Asia Pacific (Melbourne)"
    },
    {
        "code": "ap-northeast-1",
        "name": "Asia Pacific",
        "location": "Tokyo",
        "label": "Asia Pacific (Tokyo)"
    },
    {
        "code": "ap-northeast-2",
        "name": "Asia Pacific",
        "location": "Seoul",
        "label": "Asia Pacific (Seoul)"
    },
    {
        "code": "sa-east-1",
        "name": "South America",
        "location": "Sao Paulo",
        "label": "South America (Sao Paulo)"
    },
    {
        "code": "ca-central-1",
        "name": "Canada",
        "location": "Central",
        "label": "Canada (Central)"
    },
    {
        "code": "ca-west-1",
        "name": "Canada",
        "location": "Calgary",
        "label": "Canada West (Calgary)"
    },
    {
        "code": "eu-west-2",
        "name": "EU",
        "location": "London",
        "label": "EU (London)"
    },
    {
        "code": "eu-west-3",
        "name": "EU",
        "location": "Paris",
        "label": "EU (Paris)"
    },
    {
        "code": "eu-north-1",
        "name": "EU",
        "location": "Stockholm",
        "label": "EU (Stockholm)"
    },
    {
        "code": "us-gov-west-1",
        "name": "US West Government",
        "location": "N. California",
        "label": "US West Government (N. California)",
        "s3Region": "us-gov-west-1",
        isGov: true
    },
    {
        "code": "us-gov-east-1",
        "name": "US East Government",
        "location": "N. Virginia",
        "label": "US East Government (N. Virginia)",
        isGov: true
    },
    {
        "code": "cn-northwest-1",
        "name": "China",
        "location": "Ningxia",
        "label": "China (Ningxia)",
    },
    {
        "code": "cn-north-1",
        "name": "China",
        "location": "Beijing",
        "label": "China (Beijing)",
    },
    {
        "code": "ap-east-1",
        "name": "Asia Pacific",
        "location": "Hong Kong",
        "label": "Asia Pacific (Hong Kong)"
    },
    {
        "code": "me-south-1",
        "name": "Middle East",
        "location": "Bahrain",
        "label": "Middle East (Bahrain)"
    },
    {
        "code": "af-south-1",
        "name": "Africa",
        "location": "Cape Town",
        "label": "Africa (Cape Town)"
    },
    {
        "code": "eu-south-1",
        "name": "EU",
        "location": "Milan",
        "label": "EU (Milan)"
    },
    {
        "code": "eu-south-2",
        "name": "EU",
        "location": "Spain",
        "label": "EU (Spain)"
    },
    {
        "code": "ap-northeast-3",
        "name": "Asia Pacific",
        "location": "Osaka",
        "label": "Asia Pacific (Osaka)"
    },
    {
        "code": "me-central-1",
        "name": "Middle East",
        "location": "UAE",
        "label": "Middle East (UAE)"
    },
    {
        "code": "il-central-1",
        "name": "Israel",
        "location": "Tel Aviv",
        "label": "Israel (Tel Aviv)"
    }
];

export const regionsOptions = regionsList.map(region => {
    return {
        value: region.code,
        label: `${region.code} | ${region.label}`
    }
})
