import store from "store";
import _ from "lodash";
import uuidv4 from "uuid/v4";

const dataMapper = (r) => r?.data;

export const handleApiAction = async (actionOrType, promiseF) => handlePromiseAction(actionOrType, promiseF, dataMapper)

const optionalDataMapper = (r) => {
    if(r?.data) {
        return r.data
    } else {
        return r;
    }
};

export const handleSingleAction = (actionOrType, promiseF ) => {
    let type = actionOrType;
    if(_.isObject(actionOrType)) {
        type = actionOrType.type;
    }

    store.dispatch({
        type: `NOTIFICATIONS:KILL-GROUP`,
        payload: {
            group: type
        }
    });

    return handlePromiseAction(actionOrType, promiseF, optionalDataMapper);
}

export const handlePromiseAction = async (actionOrType, promiseF, responseMapper = _.identity) => {
    let type = actionOrType;
    let request = undefined;
    let trackSuccess = undefined;
    let trackError = undefined;
    let categorySuffix = undefined;
    const requestId = uuidv4();
    if(_.isObject(actionOrType)) {
        type = actionOrType.type;
        request = actionOrType;
        categorySuffix = actionOrType.categorySuffix;
        trackSuccess = actionOrType.trackSuccess || (actionOrType.trackAll && actionOrType.track);
        trackError = actionOrType.trackError || (actionOrType.trackAll && actionOrType.track);
    }

    store.dispatch({
        type: `${type}-PENDING`,
        originalType: type,
        request,
        requestId
    });

    try {
        const response = await promiseF();
        const mappedResponse = responseMapper(response);
        store.dispatch({
            type: `${type}-SUCCESS`,
            originalType: type,
            requestId,
            payload: {
                data: mappedResponse
            },
            request,
            track: trackSuccess,
            categorySuffix
        })

        return mappedResponse;
    } catch (e) {
        store.dispatch({
            type: `${type}-ERROR`,
            originalType: type,
            requestId,
            payload: e,
            request,
            track: trackError,
            categorySuffix
        })

        throw e;
    }
}
