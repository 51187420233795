import React from 'react';

import { trackExternalLink } from "utils/mrTracker";
import { ArrowTopRightIcon as ArrowIcon} from "@netapp/bxp-design-system-react/icons/monochrome";

export default ({ trackCategory, trackLabel, href, style, className, title, newTab = true, children, includeArrow, disabled }) => (
    <a href={href}
       disabled={disabled}
       onClick={() => trackCategory && trackExternalLink({category: trackCategory, label: trackLabel || href})}
       className={className}
       style={style}
       title={title}
       target={newTab ? "_blank" : undefined}
       rel={newTab ? "noopener noreferrer" : undefined}>
        {children}
        {includeArrow && <ArrowIcon style={{marginLeft: 8, width: 12, height: 9}}/>}
    </a>
);
