const randomString = function(length) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

class Auth {
    constructor(opts, {baseUrl}){
        if(!opts.clientID) throw new Error("Missing clientID from opts");
        if(!opts.audience) throw new Error("Missing audience from opts");

        this.auth = new window.auth0.WebAuth(Object.assign({
            domain: "netapp-cloud-account.auth0.com",
            redirectUri: window.location.origin,
            responseType: 'token id_token',
            scope: 'openid profile email cc:usage-dashboard gradual:deploy cleanup:admin support:gtc',
            leeway: 30
        }, opts));

        this.baseUrl = baseUrl;
        this.loginRedirect = this.loginRedirect.bind(this);
        this.refreshSso = this.refreshSso.bind(this);
        this.logout = this.logout.bind(this);
    }

    loginRedirect(auth0Opts, paramsForLoginPage, paramsForUI){
        const extra = paramsForLoginPage ? `?paramsForLoginPage=${window.btoa(JSON.stringify(paramsForLoginPage))}` : "";
        this.auth.authorize(Object.assign({state: window.btoa(JSON.stringify(Object.assign({rand: randomString(30)}, paramsForUI))), redirectUri: `${window.location.origin}/${extra}`}, auth0Opts));
    }

    testRefreshSso() {
        return new Promise((resolve, reject) => {
            this.auth.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken) {
                    try {
                        authResult.paramsForUI = JSON.parse(window.atob(authResult.state));
                    } catch (e) {
                        reject(e);
                    }
                    resolve(authResult); // Success case
                } else {
                    reject(err);
                }
            });
        });
    }

    refreshSso(auth0Opts, paramsForUI, dontRedirectErrors, email){
        const authenticate = (err, authResult, resolve, reject, firstTime) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                if (email && email !== authResult?.idTokenPayload?.email) {
                    /*
                     * Logout the user if the email passed does not matches the logged in user
                     * email id. In this case the email id will not be passed to the logged in screen.
                     */
                    this.logout()
                    resolve(authResult);
                } else {
                    try {
                        authResult.paramsForUI = JSON.parse(window.atob(authResult.state));
                    } catch (e) {
    
                    }
                    resolve(authResult); //success case
                }
            }
            else{
                //case err == null: (happens for very specific errors - auth0 doesn't generate err obj) fill with 'unknown error'
                if (!err) err = {error: "unknown error", errorDescription: "Unknown error. Try to refresh or contact support"};

                //case 'login required': set needLogin flag to true and reject err obj. Caller should then call loginRedirect
                if (err.error === "login_required") {
                    err.needLogin = true;
                    reject(err);
                } else if(dontRedirectErrors || (firstTime && (err.errorDescription === "Nonce does not match." || err.errorDescription === "`state` does not match."))){
                    reject(err);
                } else{
                    //for all other errors - redirect to NetApp SaaS Portal Error Page with included error description
                    window.location.href = `${this.baseUrl}/error-page?error=${err.error}&error_description=${err.errorDescription}`;
                }
            }
        };

        const renewOnce = (firstTime) => {
            return new Promise((resolve, reject) => {

                window.addEventListener("message", (event) => {
                    if (event.data.type === "netapp:silent-authentication"){
                        if(dontRedirectErrors){
                            reject(event.data.hash);
                        } else {
                            window.location.href = `${this.baseUrl}/error-page?${event.data.hash}`;
                        }
                    }
                });

                this.auth.renewAuth(Object.assign({
                    redirectUri: `${window.location.origin}/silent-callback.html`,
                    postMessageDataType: "auth0:silent-authentication",
                    postMessageOrigin: window.location.origin,
                    state: window.btoa(JSON.stringify(Object.assign({rand: randomString(30)}, paramsForUI))),
                    usePostMessage: true
                }, auth0Opts), (err, authResult) => {
                    authenticate(err, authResult, resolve, reject, firstTime);
                });
            })
        };

        const parseHashOnce = () => {
            return new Promise((resolve, reject) => {
                this.auth.parseHash((err, authResult) => {
                    authenticate(err, authResult, resolve, reject, true)
                });
            })
        };

        return new Promise((resolve, reject) => {
            let refreshPromise = null;
            if(window.location.hash && (window.location.hash.indexOf("access_token") > 0 || window.location.hash.indexOf("error") > 0)){
                if(window.location.href.indexOf("#!/#access_token") !== -1) window.history.pushState({}, null, window.location.href.replace("#!/"));
                refreshPromise = parseHashOnce()
            } else {
                refreshPromise = renewOnce(true)
            }

            return refreshPromise.catch(err => {
                if(err.errorDescription === "Nonce does not match." || err.errorDescription === "`state` does not match."){
                    return renewOnce(false);
                } else throw err;
            }).then(resolve, reject)
        });
    }

    logout(opts){
        this.auth.logout(Object.assign({returnTo: window.location.origin, federated: true}, opts))
    }
}

export default Auth;