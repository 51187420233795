import _ from "lodash";

const defaultState = () => ({
    status: "UNSET",
    userAccountStatus: "PENDING",
    getUserNamePending: false, 
    getUserNameShowError: false
});

const extractFirstName = fullName => (fullName || "").trim().split(" ")[0];

export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "AUTH:REFRESH-SSO-SUCCESS": {
            const isNetAppInternal = payload.idTokenPayload["http://cloud.netapp.com/internal"] === "NetApp";
            const name = payload.idTokenPayload["http://cloud.netapp.com/user_metadata"].name || "";
            const firstName = extractFirstName(name);
            const email = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email');
            const company = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].company');
            const scopeArr = (payload.scope || "").split(" ");
            const userId = _.get(payload, "idTokenPayload.sub");
            const isFederatedUser = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/is_federated"]', false);
            const redirecting = payload.paramsForUI.continueToCvoTrial || payload.paramsForUI.continueToCvoTrial2;
            const { accessToken, idTokenPayload } = payload;

            if(state.status === "SUCCESS" && state.userId !== userId) {
                console.log("Refresh SSO returned different logged in user, re-starting app");
                window.location.reload();
            }

            return {
                ...state,
                status: "SUCCESS",
                isAuthenticated: true,
                userId,
                accessToken,
                idTokenPayload,
                isEmailVerified: payload.idTokenPayload['email_verified'],
                firstName,
                name,
                isFederatedUser,
                scopes: _(scopeArr).keyBy().mapValues(_.constant(true)).value(),
                isNetAppInternal,
                email,
                company,
                redirecting
            };
        }
        case "AUTH:RENEW-SSO-TIMEOUT": {
            return {...state, renewTimeout: true}
        }
        case "AUTH:LOGIN-PENDING": {
            return {...state, status: "PENDING"}
        }
        case "AUTH:REFRESH-SSO-FAILED": {
            return { status: 'UNSET'};
        }

        case "AUTH:THIRD-PARTY-AUDIENCE-PENDING": {
            return { ...state, isFetchingThirdPartyToken: true }
        }
        case "AUTH:THIRD-PARTY-AUDIENCE-SUCCESS": {
            return { ...state, isFetchingThirdPartyToken: false, thirdPartyToken: payload.data.accessToken }
        }
        case "AUTH:THIRD-PARTY-AUDIENCE-ERROR": {
            return { ...state, isFetchingThirdPartyToken: false }
        }

        case "USER:UPDATE-USERNAME": {
            return {
                ...state,
                getUserNamePending: true,
                getUserNameShowError: false,
            };
        }
        case "USER:UPDATE-USERNAME-SUCCESS": {
            const name = payload.userName;
            return {
                ...state,
                name,
                getUserNamePending: false,
                getUserNameShowError: false,
            };
        }
        case "USER:UPDATE-USERNAME-ERROR": {
            return {
                ...state,
                getUserNamePending: false,
                getUserNameShowError: true,
            };
        }
        default: {
            return state;
        }
    }
};
