import _ from "lodash";
import notificationsConfig from "./notificationsConfig";
import { unknownName } from "./notificationsConfig";

const defaultTypeFilter = {
    values:{
        critical:true
    }
}

const defaultState = () => ({
    typeFilter: defaultTypeFilter,
    servicesFilter: {},
    notifications: []
});

const priorityValues = {
    success: 0,
    info: 1,
    recommendation:2,
    warning: 3,
    error: 4,
    failed: 5,
    critical:6

}


function dismissSingleMessage(notifications,notificationIdToDismiss) {
    return notifications.filter(n => n.id !== notificationIdToDismiss);
}

export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "UI-NOTIFICATIONS:DISMISS-ONE-SUCCESS": {
            const notifications =  dismissSingleMessage(state.notifications,payload?.data?.id)
            const highestPriority = _.maxBy(notifications, n => priorityValues[n.priority])?.priority;

            return { ...state, notifications, highestPriority }
        }
        case "UI-NOTIFICATIONS:DISMISS-ALL-PENDING": {
            return { ...state, isDismissingAll: true }
        }
        case "UI-NOTIFICATIONS:DISMISS-ALL-SUCCESS": {
            return { ...state, notifications: [], isDismissingAll: false, highestPriority: undefined }
        }
        case "UI-NOTIFICATIONS:DISMISS-ALL-ERROR": {
            return { ...state, isDismissingAll: false }
        }

        case "UI-NOTIFICATIONS:SET-FILTER": {
            let key = payload.id;
            const { values } = payload;
            const filter = `${key}Filter`;
            return {
                ...state, [filter]: {
                    values: key === "type" ?  {...values, critical:true} : values,
                    activeCount: _.sumBy(_.values(values), v => {
                        return v ? 1 : 0;
                    })
                }
            }
        }
        case "UI-NOTIFICATIONS:CLEAR-FILTERS": {
            return { ...state, typeFilter:defaultTypeFilter, servicesFilter: {} }
        }

        case "UI-NOTIFICATIONS:MESSAGE-RECEIVED": {
            console.debug(payload)
            if (!payload.action || !payload.timestamp || !payload.priority || !payload.service) {
                console.warn("Notification dropped", payload)
                return state;
            }

            const notificationId = payload.notificationId || null

            if (notificationId != null && _.some(state.notifications, ['notificationId', notificationId])) {
                console.warn("duplicated notification dropped", payload)
                return state
            }

            if (!_.includes(_.keys(priorityValues), payload.priority)) {
                console.warn(`message dropped, priority value in message must be one of values: ${_.keys(priorityValues)}`, payload)
                return state
            }

            let priority = payload.priority;
            if (priority === "failed") {
                priority = "error";
            }

            let highestPriority = state.highestPriority;
            if (!state.highestPriority || priorityValues[priority] > priorityValues[highestPriority]) {
                highestPriority = priority;
            }


            if (!payload.params) {
                payload.params = {}
            }

            let errors = null
            let labels = null;

            const config = notificationsConfig[payload.service]?.[payload.action];
            if (config) {
                const resourceName = config.getResourceName({ payload })
                if (resourceName === unknownName) {
                    if (!payload.params.resourceName) {
                        payload.params.resourceName = unknownName
                    }
                } else {
                    payload.params.resourceName = resourceName
                }
                labels = config.getLabels({ payload });
            } else  {
                labels = {
                    action: `${payload.action}`
                }
            }


            if (payload.errors) {
                try {
                    const parsedErrors = JSON.parse(payload.errors)
                    if (parsedErrors.length) {
                        errors = parsedErrors
                    } else {
                        errors = [payload.errors]
                    }
                } catch {
                    errors = [payload.errors]
                }
            }

            let descriptions = []

            if (priority === "error" && errors) {
                descriptions = errors
            } else if (priority !== "error" && !_.isEmpty(payload.description)) {
                descriptions = [payload.description];
            }

           const  notifications =
                [
                    {
                        stored: payload.stored,
                        notificationId,
                        id: payload.id,
                        label: labels?.action || payload.action,
                        timestamp: payload.timestamp,
                        priority,
                        service: payload.service,
                        descriptions: descriptions,
                        cta: payload.cta,
                        link: payload.link
                    },
                    ...state.notifications
                ]


            return {
                ...state,
                highestPriority,
                notifications: [..._.filter(notifications,item => item.priority === 'critical'), //make critical notifications always on top of the list
                    ..._.filter(notifications,item => item.priority !== 'critical')]
            }
        }

        default: {
            return state;
        }
    }
};
