import _ from "lodash";

export const reflect = promise => promise.then(
    value => ({ value, status: "fulfilled" }),
    error => ({ error, status: "rejected" })
);

export const resolve = async promise => {
    const resolution = {};
    try {
        resolution.value = await promise;
    } catch (e) {
        resolution.error = e;
    }
    return resolution;
};

// This behaves like Promise.all, but will always execute all promises, even if one of them fails.
export const executeAllPromises = promises =>
    Promise.all(_.map(promises, reflect))
        .then(results => {
            if (_.every(results, { status: 'fulfilled' })) {
                return _.map(results, 'value');
            }
            const firstErrorMessage = _.find(results, { status: 'rejected' }).error;
            return Promise.reject(firstErrorMessage);
        });
