import React from "react";
import { useSelector } from "react-redux";
import styles from "../MainHeader.module.scss";
import { SettingsIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { HeaderSettingsIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import SlidingWidget from "./SlidingWidget";
import MenuLinkItem from "./MenuLinkItem";
import _ from "lodash";
import { isNewTenancyEnabled } from "utils/localstorage";

const Content = React.memo(() => {
    const activeAgent = useSelector(state => state.tenancy.selectedAgent);
    const noConnector = process.env.REACT_APP_IS_SAAS === "true" && !activeAgent;
    const darkSite = process.env.REACT_APP_IS_DARK === 'true';
    const installLocation = useSelector(state => state.app.instancePlacement?.installLocation?.toLowerCase());
    // Get the Feature flag state from Redux state.
    const isTenancyV4 = isNewTenancyEnabled();
    const { forbidden, permissions } = useSelector(state => state.tenancy);

    return <div className={styles['menu-content']}>
        {/* If Tenancy V4 enabled from feature flag, then show this Menu Item in settings page. */}
        {isTenancyV4 && permissions.editAccount && !darkSite && <MenuLinkItem to="/identity-access-mgmt/organization">Identity & Access Management</MenuLinkItem>}
        {permissions.allTabsAvailable && <MenuLinkItem disabled={noConnector} to="/connector-settings/settings">Cloud Volumes ONTAP Settings</MenuLinkItem>}
        {permissions.allTabsAvailable && <MenuLinkItem to="/timeline">Timeline</MenuLinkItem>}
        {permissions.allTabsAvailable && <MenuLinkItem disabled={darkSite && installLocation === "onprem"} to="/credentials/account-credentials">Credentials</MenuLinkItem>}
        {permissions.allTabsAvailable && <MenuLinkItem disabled={noConnector} to="/connector-settings/certificate-setup">HTTPS Setup</MenuLinkItem>}
        {permissions.allTabsAvailable && !darkSite && <MenuLinkItem to="/alerts-and-notifications/users">Alerts and Notifications Settings</MenuLinkItem>}
    </div>;
});

export default () => {
    const { forbidden, permissions } = useSelector(state => state.tenancy);
    const disabled = (process.env.REACT_APP_IS_SAAS !== "true" && forbidden) || (!permissions.allTabsAvailable && !permissions.editAccount);

    return <SlidingWidget
        widgetKey="settings"
        menuTitle="Settings"
        MenuIcon={SettingsIcon}
        triggerClassName={styles['widget-button']}
        triggerActiveClassName={[styles['widget-button-active']]}
        triggerChildren={<HeaderSettingsIcon size={24} display="block"/>}
        triggerDisabled={disabled}
    >
        <Content/>
    </SlidingWidget>;
};
