import React from 'react';
import { Modal, MainLoader, FormFieldInputNew, useForm, ActionButton, SvgLazy, ExternalLink, Heading, Text } from "@netapp/bxp-design-system-react";
import { ErrorMonochromeIconsIcon as Error } from "@netapp/bxp-design-system-react/icons/monochrome";
import styles from "./NoAccountDialog.module.scss"
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import { setItemAnon } from "utils/localstorage";

const accountFormSchema = yup.object().shape({
    accountName: yup
        .string()
        .matches(/^[a-zA-Z0-9]*$/, "Name may contain letters or numbers and max length should be 40 characters.")
        .required("Name is required")
});

export default React.memo(() => {
    const isReloading = useSelector(state => state.app.isReloading);
    const name = useSelector(state => state.auth.firstName);
    const isCreatingAccount = useSelector(state => state.tenancy.creatingAccount);
    const creatingAccountFailed = useSelector(state => state.tenancy.creatingAccountFailed);
    const dispatch = useDispatch();

    const form = useForm({accountName: ""}, accountFormSchema);
    const { handleFormSubmit, formState } = form;

    const createAccount = () => {
        setItemAnon({itemKey: `dontShowNewBlue`}, true)
        dispatch({
            type: 'TENANCY:CREATE-ACCOUNT',
            payload: {accountName: formState.accountName}
        });
    };

    if(isReloading) {
        return <MainLoader/>
    }

    return <Modal className={styles.base}>
        <div className={styles['create-first-account']}>
            <div className={styles['left-side']}>
                <Heading bold color="text-title">Hi {name},<br/>Welcome to BlueXP</Heading>
                <SvgLazy srcP={import("./woman-shpagat.svg")}/>
            </div>
            <div className={styles['right-side']}>
                <Text level="16">Let's get started by creating an account for your organization.</Text>
                <Text level="16">If your organization already has an existing account, it's best to ask the account admin to add you to it.
                    <ExternalLink
                        variant="text"
                        trackCategory="Tenancy"
                        trackLabel="First Account - Learn how to Add User"
                        href="https://docs.netapp.com/us-en/occm/task_setting_up_cloud_central_accounts.html#adding-users"
                        newTab> Learn how to add a user.</ExternalLink>
                </Text>
                <FormFieldInputNew
                    name="accountName"
                    label="Account Name"
                    disabled={isCreatingAccount}
                    form={form}
                    autoComplete="off"
                />
                <ActionButton onClick={handleFormSubmit(createAccount)} isSubmitting={isCreatingAccount}>Create Account</ActionButton>
                {creatingAccountFailed && <div className={styles.failed}><Error/>Account creation failed</div>}
            </div>
        </div>
    </Modal>;
});
