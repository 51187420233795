import React, {useEffect, useState, useRef} from "react";
import _ from "lodash";
import {ComponentLoader, Notification} from "@netapp/bxp-design-system-react";
import styles from "../MainHeader.module.scss";
import {FixedSizeList as List} from "react-window";
import serviceConnectorMenuStyles from "./ConnectorMenu.module.scss";
import Measure from "react-measure";

export default React.memo(({status, errorReason, Item, items, itemData, itemSize, setSelectedItem, activeItem, heightOffset, label, scrollAlign = "center", style}) => {
    const [height, setHeight] = useState(window.innerHeight - heightOffset);
    const listRef = useRef(null);

    useEffect(() => {
        setSelectedItem(activeItem);

        if(activeItem && items && listRef.current) {
            const activeAgentIndex = _.findIndex(items, activeItem);
            listRef.current.scrollToItem(activeAgentIndex, scrollAlign);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem, listRef, setSelectedItem]);

    return <Measure
        bounds
        onResize={contentRect => {
            setHeight(contentRect.bounds.height);
        }}
    >
        {({ measureRef }) =>
            <div ref={measureRef} style={{overflow: "auto"}}>
                {status === "PENDING" && <div>
                    <ComponentLoader className={styles["menu-loader"]}/>
                </div>}

                {status === "FAILED" && <Notification type="error" moreInfo={errorReason} className={styles['notification']}>
                    Failed to load {label}
                </Notification>}

                {status === "SUCCESS" && <List
                    height={height}
                    itemCount={items.length}
                    itemData={itemData}
                    itemSize={itemSize}
                    width="100%"
                    className={serviceConnectorMenuStyles['list']}
                    style={style}
                    ref={listRef}
                >
                    {Item}
                </List>}
            </div>
        }
    </Measure>

});
