import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Modal, ModalHeader, ModalContent, ModalFooter, Notification, Button, StyledLink, Text } from "@netapp/bxp-design-system-react";

export default () => {
    const dispatch = useDispatch();
    const selectedAgentId = useSelector(state => state.tenancy.selectedAgentId);

    const discardChangeAgentDialog = () => {
        dispatch({
            type: 'TENANCY:DISCARD-CHANGE-AGENT-MESSAGE'
        })
    };

    const openConnectorMenu = () => {
        discardChangeAgentDialog();

        dispatch({
            type: 'MENU:OPEN-WIDGET',
            payload: 'connectors'
        });
    };

    return <Modal>
        <ModalHeader>
            Inactive BlueXP connector
        </ModalHeader>
        <ModalContent>
            <Notification type="error" style={{marginBottom: 35}}>
                The BlueXP connector that you had been using is now inactive.
            </Notification>
            {selectedAgentId && <>
                <Text>Check the network connectivity for the inactive BlueXP connector and try to fix the problem.</Text>
                <Text>In the meantime, we switched you to an active BlueXP connector so that you can continue working. Feel free to choose a different BlueXP connector if you need to.</Text>
            </>}
            {!selectedAgentId && <Text>Check the network connectivity for the inactive BlueXP connector and try to fix the problem, or create a new BlueXP connector.</Text>}
        </ModalContent>
        <ModalFooter>
            {selectedAgentId && <Button onClick={openConnectorMenu}>Switch BlueXP connectors</Button>}
            {!selectedAgentId && <StyledLink to={location => ({...location, hash: "addConnector"})} onClick={discardChangeAgentDialog}>Create BlueXP Connector</StyledLink>}
            <Button color="secondary" onClick={discardChangeAgentDialog}>Close</Button>
        </ModalFooter>
    </Modal>;
};
