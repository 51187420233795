import React, { useState, useMemo } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import styles from "./../MainHeader.module.scss";
import SlidingWidget, {CaptionButton, SlidingWidgetFooter} from "./SlidingWidget"
import { Checkbox, MenuPopover, CopyMenuItem, SearchWidget } from "@netapp/bxp-design-system-react"
import ListMenuContent from "./ListMenuContent";
import serviceConnectorMenuStyles from "./ConnectorMenu.module.scss";
import _ from "lodash";

const ChangeWorkSpaceButton = ({ index, style, data: {workspaces, setSelectedWorkspace, selectedWorkspace} }) => {
    const workspace = workspaces[index];
    const isSelected = selectedWorkspace === workspace;

    return <Checkbox style={style} className={classNames(styles['menu-content-list-button'], styles['menu-content-link-button'], styles['no-top-border'], {[styles['menu-content-link-button-active']]: isSelected})} onChange={() => {
        if(isSelected) {
            return;
        }

        setSelectedWorkspace(workspace);
    }} checked={isSelected} ariaLabel={workspace.workspaceName}>
        <span>{workspace.workspaceName}</span>
        <MenuPopover
            Trigger="oval"
            triggerStyle={{margin: "0px 5px 0px auto"}}
            menu={[{
                Component: () => <CopyMenuItem label="Workspace ID" value={workspace.workspacePublicId}/>
            }]}
        />
    </Checkbox>
};

const WorkspaceMenuFooter = ({activeWorkspace, selectedWorkspace}) => {
    const dispatch = useDispatch();

    return <SlidingWidgetFooter onSwitch={() => {
        if(activeWorkspace === selectedWorkspace){
            return;
        }

        dispatch({
            type: "TENANCY:SET-WORKSPACE",
            payload: {
                workspaceId: selectedWorkspace.workspacePublicId
            },
            track: true
        });

        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }} onClose={() => {
        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }}/>
};

const emptyWorkspace = [];
const MenuContent = React.memo(({selectedWorkspace, setSelectedWorkspace}) => {
    const activeWorkspace = useSelector(state => state.tenancy.selectedWorkspace);
    const workspaces = useSelector(state => state.tenancy.workspaces) || emptyWorkspace;
    const workspaceStatus = useSelector(state => state.tenancy.workspaceStatus);
    const [activeFilter, setActiveFilter] = useState("");
    const filteredWorkspaces = useMemo(() => {
        if(activeFilter) {
            const lowerCasedFilter = _.toLower(activeFilter);
            return _.filter(workspaces, workspace => {
                return _.includes(_.toLower(workspace.workspaceName), lowerCasedFilter);
            })
        } else {
            return workspaces;
        }
    }, [activeFilter, workspaces]);

    return <div style={{display: "grid", gridTemplateRows: "65px 1fr", gridRowGap: 16, height: "100%"}}>
        <SearchWidget color="secondary" className={serviceConnectorMenuStyles['search-widget-container']} alwaysOpen={true} placeholder="Search Workspaces" setFilter={setActiveFilter} widgetClassName={serviceConnectorMenuStyles['search-widget']}/>
        <ListMenuContent
            itemSize={59}
            label="workspaces"
            heightOffset={260}
            Item={ChangeWorkSpaceButton}
            status={workspaceStatus}
            activeItem={activeWorkspace}
            selectedItem={selectedWorkspace}
            setSelectedItem={setSelectedWorkspace}
            items={filteredWorkspaces}
            itemData={{workspaces: filteredWorkspaces, selectedWorkspace, setSelectedWorkspace}}
        />
    </div>
});

export default () => {
    const activeWorkspace = useSelector(state => state.tenancy.selectedWorkspace);
    const [selectedWorkspace, setSelectedWorkspace] = useState(activeWorkspace);
    const workspaceName = activeWorkspace?.workspaceName || "N/A";

    return <SlidingWidget
        widgetKey="workspaces"
        menuTitle="Workspaces"
        triggerClassName={styles['caption-button']}
        triggerActiveClassName={[styles['caption-button-active']]}
        TriggerButton={(props) => <CaptionButton title="Workspace" value={workspaceName} {...props}/>}
        MenuFooter={() => <WorkspaceMenuFooter activeWorkspace={activeWorkspace} selectedWorkspace={selectedWorkspace}/>}
    >
        <MenuContent selectedWorkspace={selectedWorkspace} setSelectedWorkspace={setSelectedWorkspace}/>
    </SlidingWidget>
};
