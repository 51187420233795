import _ from "lodash";
import { keys } from "@netapp/bxp-design-system-react";

/**
 * Achieve accessibility for navigating menu items using this custom hook. It enables keyboard navigation with key up and key down events.
 * TODO: a) Extend support for horizontal menu bars in addition to vertical menu bars.
b) Add aria-properties to each menu item.
 * @param {*} menuElements An array of references to the menu items. 
 * @param {*} refsForHandlingKeyDownEvent  An array of references or method references for keydown event mapping of each menu item. Each item in the array represents an object where the keys are keycodes and the values are either element references or method references. For example, if refsForHandlingKeyDownEvent has the first value as {27: ${headerRef}, 39: ${clickMethod}}, then for the first menu element, pressing the Escape key (key code 27) will focus the headerRef, and pressing the Right Arrow key (key code 39) will invoke the clickMethod. ${headerRef} is a reference obtained using useRef, and clickMethod is a method reference. (Ex: a. refsForHandlingKeyDownEvent.current[index][keys.esc] = categoryRef.current; b. refsForHandlingKeyDownEvent.current[index][keys.right] = categoryRef.click.bind(categoryRef);
 * Note: refsForHandlingKeyDownEvent and menuItemsRefs list order should be the same. If '0'th index of menuItemsRefs has ref of "abc" menu, then '0'th index of refsForHandlingKeyDownEvent should have key events mapping of "abc" menu only.
 * @returns the keydown event and click event handlers which should be attached on each menu item.
 */

const useMenuAccessibility = (menuElements = [], refsForHandlingKeyDownEvent = [{}]) => {
    /**
     * Invoked when a key down event is triggered on a menu item.
     * @param {*} currentFocussedMenuIndex: index of the menu in the menuItemsRefs which is currently focussed.
     * @param {*} event: Keydown event
    * TODO: We can enhance this method to handle few common key events if it's not defined in refsForHandlingKeyDownEvent. For example, on click of "Esc" key, we can close the menu and navigate to the next focussable element by default. 
     */
    const handleKeyDown = (currentFocussedMenuIndex, event) => {
        if (!menuElements?.length) {
            return;
        }
        /**
         * Handling keydown and keyup events to navigate through the menu items. TODO: This works for vertical menu bar, but should support horizontal menu bar as well with right and left arrow keys navigation.
         */
        if (event.keyCode === keys.down && currentFocussedMenuIndex < menuElements?.length - 1) {
            event.preventDefault();
            menuElements[currentFocussedMenuIndex + 1].focus();
        } else if (event.keyCode === keys.up && currentFocussedMenuIndex > 0) {
            event.preventDefault();
            menuElements[currentFocussedMenuIndex - 1].focus();
        } else if (refsForHandlingKeyDownEvent.current[currentFocussedMenuIndex]) {
            /**
             * If any custom handling is needed on any key press, it will be passed in refsForHandlingKeyDownEvent. For each menu item, there can be multiple key codes mapping. If we pass ref of an element as value for "refsForHandlingKeyDownEvent.current[menuIndex][event.keyCode]", then that element will be focussed. If a method reference is passed as a value to "refsForHandlingKeyDownEvent.current[menuIndex][event.keyCode]", then then method will be called.
             */
            const focusRef = refsForHandlingKeyDownEvent.current[currentFocussedMenuIndex][event.keyCode];
            if (focusRef) {
                typeof focusRef === 'function' ? focusRef() : focusRef.focus();
                event.preventDefault();
            }
        }
    };

    return {
        onKeyDown: (currentFocussedMenuIndex, event) => handleKeyDown(currentFocussedMenuIndex, event)
    };
};

export default useMenuAccessibility;
