import { SearchIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { Button } from "@netapp/bxp-design-system-react";
import { useDispatch, useSelector } from "react-redux";
import { Suspense, useState } from "react";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { lazy } from "react-imported-component/macro";

const widgetCss = css`
  margin-right: 40px;
  color: var(--text-on-color);
  border: 1px solid var(--text-on-color);
  border-radius: 50px;
  display: inline-flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 32px;
  
  --icon-primary: var(--text-on-color);

  svg {
    width: 14px;
    height: 14px;
  }
`;

const lazyImport = () => import('modules/global-search'/* webpackChunkName: "GlobalSearch" */).then(r => r.Component);
const GlobalSearch = lazy(lazyImport);

const GlobalSearchWidget = () => {
    const dispatch = useDispatch();
    const [buttonBoundingRect, setButtonBoundingRect] = useState(null);
    const isGlobalSearchOpen = useSelector(state => state.globalSearch?.isOpen);

    if (!isGlobalSearchOpen) {
        return <Button css={widgetCss} type="none" onClick={(e) => lazyImport().then(() => {
            setButtonBoundingRect(e.target.getBoundingClientRect())
            dispatch({
                type: "GLOBAL-SEARCH/OPEN"
            })
        })}>
            <SearchIcon style={{ marginRight: 9, marginTop: 2 }}/> BlueXP Search
        </Button>
    } else {
        return <Suspense fallback={<div/>}>
            <GlobalSearch buttonBoundingRect={buttonBoundingRect}/>
        </Suspense>
    }
}
;

export default GlobalSearchWidget;