import { occmClient } from "utils/clients";

const apiCall = async(payload,dispatch) => {
    if(payload.config){
        try{
            const res= await occmClient(payload.config);
            dispatch({type: "OCCM-PROXY:API-SUCCESS", payload: {status:'success',statusCode:res?.status,data:res?.data,requestId:payload.requestId}})
        }
        catch(e){
                dispatch({type: "OCCM-PROXY:API-FAILED", payload: {status:'error',statusCode:e?.response?.status,data:e?.response?.data?.message,requestId:payload.requestId}})
        }
    }
};


export default ({dispatch}) => next => async action => {
    const { type, payload } = action;
    if (type === "SERVICE:OCCM-PROXY") {
         apiCall(payload,dispatch);
    }
    return next(action);
};
