const defaultState = () => ({
    responses: []
});

export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "OCCM-PROXY:API-SUCCESS": {
            return {
                ...state,
                responses: state.responses.concat({
                    type: "OCCM-PROXY-RESPONSE",
                    payload
                })
            }
        }
        case "OCCM-PROXY:API-FAILED": {
            return {
                ...state,
                responses: state.responses.concat({
                    type: "OCCM-PROXY-RESPONSE",
                    payload
                })
            }
        }
        case "OCCM-PROXY:RESPONSES-HANDLED": {
            return {
                ...state,
                responses: state.responses.filter(response => !payload.includes(response))
            };
        }
        case "SUPPORT:NSS-ADDED": {
            return {
                ...state,
                responses: state.responses.concat({
                    type: "NSS-ADDED"
                })
            }
        }
        case "SUPPORT:ADD-NSS-FAILED": {
            return {
                ...state,
                responses: state.responses.concat({
                    type: "ADD-NSS-FAILED"
                })
            }
        }
        default: {
            return state;
        }
    }
};
