const defaultState = () => ({
    active: null,
    activePayload: null
});

export default (state = defaultState(), { type, payload }) => {
    if(type.endsWith(":OPEN-MODAL")) {
        const actionType = type.slice(0, -":OPEN-MODAL".length);
        return {
            active: actionType,
            activePayload: payload
        }
    } else if (type.endsWith(":CLOSE-MODAL")) {
        const actionType = type.slice(0, -":CLOSE-MODAL".length);
        if(state.active === actionType) {
            return defaultState()
        } else {
            return state;
        }
    } else if(type === "CLEAR-CONTEXT" && state.active && state.active === payload.context) {
        return defaultState();
    } else {
        return state;
    }
};
