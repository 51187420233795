import externals from "../externals";
import  {assign,isEmpty} from 'lodash'

const max_connection_retries = 10

function WebSocketClient(config){
    this.url = config.url
    this.accountId = config.accountId
    this.getToken = config.getToken
    this.onopen = config.onOpen
    this.onclose = config.onClose
    this.onmessage = config.onMessage
    this.onerror = config.onError
    this.autoReconnectInterval = 5*1000;	// ms
    this.connectionFailures = 0;
}
WebSocketClient.prototype.open = function(){

    this.instance = new WebSocket(`${this.url}?token=${this.getToken()}&accountId=${this.accountId}`);

    this.instance.onopen = (e) => {
        this.connectionFailures = 0;
        this.onopen(e);
    };
    this.instance.onclose = (e) => {
        switch (e.code){
            case 1000:	// CLOSE_NORMAL
                break;
            default:	// Abnormal closure
                this.reconnect();
                break;
        }
        this.onclose(e);
    };
    this.instance.onmessage =  (e) => {

        if(!isEmpty(e.data)){
            const data = JSON.parse(e.data)
            this.onmessage(data)
        }

    };
    this.instance.onerror = (e) => {
        switch (e.code){
            case 'ECONNREFUSED':
                this.reconnect();
                break;
            default:
                this.onerror(e);
                break;
        }
    };

}

WebSocketClient.prototype.reconnect = function(){
    if(this.connectionFailures >= max_connection_retries){
        console.debug(`quit ws reconnection process, reached max ${max_connection_retries} retries`)
        return
    }

    setTimeout(() =>{
        console.debug(`reconnecting ws...`)
        this.connectionFailures++
        this.open();
    },this.autoReconnectInterval);
}

WebSocketClient.prototype.close = function() {
    this.instance.close(1000, "Manually closed");
}

export const createWebSocketClient = (config) =>{
    const wsClient = new WebSocketClient(assign(config,{url: externals.uiNotificationWebSocket}) );

    wsClient.open()

    return wsClient
}
