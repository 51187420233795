import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import classNames from "classnames";
import { SlidingHeaderMenu, TransitionChevron, keys } from "@netapp/bxp-design-system-react";
import styles from "../MainHeader.module.scss";
import buttonStyles from "@netapp/bxp-design-system-react/styles/buttons.module.scss";
import { focusContainerFirstEle } from "../accessibility/AccecibilityUtil";

function noFocus(e) {
    e.preventDefault();
};

export const CaptionButton = ({ title, value, isActive, className, onClick, buttonRef, style, isDisabled, onKeyDown }) => {
    return <button ref={buttonRef} onMouseDown={noFocus} onClick={onClick} title={value} className={className} style={style} disabled={isDisabled} onKeyDown={onKeyDown}>
        <div className={styles['caption-button-title']}>{title}</div>
        <div className={styles['caption-button-value']}>{value}</div>
        <TransitionChevron isActive={isActive} className={styles['caption-chevron']}/>
    </button>
};

export const SlidingWidgetFooter = ({ onSwitch, onClose }) => {
    return <div className={styles['sliding-menu-footer']}>
        <button onClick={onSwitch} className={buttonStyles['primary']}>Switch</button>
        <button onClick={onClose} className={buttonStyles['secondary']}>Cancel</button>
    </div>
};

export default ({ widgetKey, MenuActionButton, HeaderContents, MenuIcon, menuTitle, children, triggerChildren, TriggerButton, triggerClassName, triggerActiveClassName, MenuFooter, canClose, triggerDisabled, className }) => {
    const isActive = useSelector(state => state.menu.activeWidget === widgetKey);
    const dispatch = useDispatch();
    const buttonRef = useRef(null);

    /********** For accessibility **************/
    const slidingHeaderMenuRef = useRef(null);
    /**
     * Using the util method to focus the first element inside the sliding container when the user presses enter or space on the trigger button.
     */
    const handleTargetBtnKeyDown = (event) => {
        if (!isActive && (event?.keyCode === keys.enter || event?.keyCode === keys.space)) {
            focusContainerFirstEle(slidingHeaderMenuRef, true);
        }
    }
    /*******************************************/

    const open = () => {
        dispatch({
            type: "MENU:OPEN-WIDGET",
            payload: widgetKey
        })
    };

    const close = () => {
        if (!canClose || canClose()) {
            dispatch({
                type: "MENU:CLOSE-WIDGET"
            })
        }
    };

    const toggleMenu = () => {
        if (isActive) {
            close();
        } else {
            open();
        }
    };

    return <>
        {triggerChildren && <button disabled={triggerDisabled} ref={buttonRef} className={classNames(triggerClassName, { [triggerActiveClassName]: isActive })} onMouseDown={noFocus} onClick={toggleMenu} aria-label={menuTitle} onKeyDown={handleTargetBtnKeyDown}>
            {triggerChildren}
        </button>}
        {TriggerButton && <TriggerButton disabled={triggerDisabled} buttonRef={buttonRef} isActive={isActive} className={classNames(triggerClassName, { [triggerActiveClassName]: isActive })} onClick={toggleMenu} menuTitle={menuTitle} onKeyDown={handleTargetBtnKeyDown} />}
        <SlidingHeaderMenu ref={slidingHeaderMenuRef} className={className} contentClassName={styles['sliding-menu-content']} Footer={MenuFooter} maskClassName={styles['sliding-menu-mask']} containerClassName={styles['sliding-header-menu']} isActive={isActive}
            closeTrigger={(e, escClicked) => {
                /**
                 * This is for accessibility. Focus the trigger button if the panel is closed by clicking on escape.
                 */
                if (escClicked) {
                    // without setTimeout the element is not getting focussed.
                    setTimeout(() => {
                        buttonRef.current.focus();
                    });
                }
                if (e.target !== buttonRef.current) {
                    close();
                }
                           }} title={menuTitle} Icon={MenuIcon} ActionButton={MenuActionButton} HeaderContents={HeaderContents}>
            {children}
        </SlidingHeaderMenu>
    </>
};
