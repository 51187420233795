import { handlePromiseAction } from "../../utils/actions";
import { createWebSocketClient } from "../../utils/websocket/client";
import { apiClient } from "utils/clients";
import externals from "utils/externals";
import _ from "lodash"

let websocketClientConnection = null;
export default ({ dispatch, getState }) => next => async action => {
    const { type, payload } = action;

    const onMessage = (msg)=>{

        dispatch({
            type: "UI-NOTIFICATIONS:MESSAGE-RECEIVED",
            payload: msg
        })
    }
    if (type === "UI-NOTIFICATIONS:CONNECT") {
        if(websocketClientConnection) {
            websocketClientConnection.close();
        }

        websocketClientConnection = createWebSocketClient({
            accountId: getState().tenancy.selectedAccountId,
            getToken: function () {
                return getState().auth.accessToken
            }, onOpen: function (e) {
                dispatch({
                    type: "UI-NOTIFICATIONS:CONNECTION-OPEN",
                    payload: {
                        event: e
                    }
                })
            }, onClose: function (e) {
                dispatch({
                    type: "UI-NOTIFICATIONS:CONNECTION-CLOSED",
                    payload: {
                        event: e
                    }
                })
            }, onMessage: function (data) {
                if (data.type === "deleted"){
                    console.debug("deleted", data)
                    dispatch({
                        type: "UI-NOTIFICATIONS:DISMISS-ONE-SUCCESS",
                        payload: {data}
                    })
                    return
                }

                onMessage(data)

            }, onError: function (e) {
                dispatch({
                    type: "UI-NOTIFICATIONS:CONNECTION-ERROR",
                    payload: {
                        event: e
                    }
                })
            }
        })
    } else if (type === "UI-NOTIFICATIONS:FETCH-MESSAGES") {

        try{
            const {data} = await apiClient.get(`${externals.uiNotificationBackend}/messages/${getState().tenancy.selectedAccountId}`);

            _.forEach(data,(message) => onMessage(message))

        }catch (e) {
            console.warn("Could not fetch messages", e)
        }


    }else if (type === "UI-NOTIFICATIONS:DISMISS-ONE") {
        handlePromiseAction(action, async () => {
            if (payload.stored) {
                await apiClient.delete(`${externals.uiNotificationBackend}/messages/${payload.id}`);
            }
            return Promise.resolve({
                id:payload.id
            })
        })
    } else if (type === "UI-NOTIFICATIONS:DISMISS-ALL") {
        handlePromiseAction(action,  async () => {
            return await apiClient.post(`${externals.uiNotificationBackend}/messages/dismiss-all/${getState().tenancy.selectedAccountId}`,{
                messagesIds: payload.ids
            });
        })
    }

    return next(action);
}
