import { useMemo } from "react";
import { useLocation, matchPath } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";

export default () => {
    const location = useLocation();
    const services = useSelector(state => state.app.services);
    return useMemo(() => {
        return _.find(services, service => {
            const serviceTo = _.get(service, "service.to");
            if(service.suite && service.suite !== "CloudManager") {
                return false;
            }
            const addWorkingEnvironmentService = matchPath(location.pathname, `/add-working-environment/${service.key}/:provider?/:isHa?/:createOrDiscover`);
            return addWorkingEnvironmentService || (serviceTo && matchPath(location.pathname, serviceTo));
        })
    }, [location.pathname, services])
}
