import React, { useMemo } from 'react';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
    Button, FormFieldInputNew, Modal, FormFieldSelect,
    ModalContent, ModalFooter, ModalHeader, Layout, useForm,Text
} from "@netapp/bxp-design-system-react";
import styles from "./GTCDataCollectionPage.module.scss"
import countriesList from "./countriesList";

const invalidBusinessAddressError = 'Business address must have between 3-400 English characters and can only contain alphabetic characters (a-z), numbers (0-9), commas (,), periods (.), and hyphens (-).';
const invalidCityError = 'City must have between 2-255 English characters and can only contain alphabetic characters (a-z), periods (.), and hyphens (-).';
const invalidStateError = 'State must have between 2-255 English characters and can only contain alphabetic characters (a-z), periods (.), and hyphens (-).';
const invalidPostalCodeError = 'Postal code must have between 3-15 English characters and can only contain alphabetic characters (a-z), numbers (0-9) and hyphens (-).';
const invalidPhoneError = 'Phone number must use the following international format: +CCC.NNNNNNNNNN, where C is the 1-3 digit country code and N between 4-14 digits. The plus sign that precedes the country code and the dot that follows the country code are both required.';

const initialData = {
    name: undefined,
    company: undefined,
    country: undefined,
    businessAddress: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    phone: undefined
};

const schemaObject = {
    country: yup.string().required('Country is required.'),
    businessAddress: yup.string()
        .required("Business address is required.")
        .min(3, "Business address must contain at last 3 characters.")
        .max(400, "Business address must have between 3 and 400 characters.")
        .matches(/^[a-zA-Z0-9,.-][ a-zA-Z0-9,.-]{2,399}$/, invalidBusinessAddressError),
    city: yup.string()
        .required("City is required.")
        .min(2, "City name must contain at least 2 characters.")
        .max(255, "City name must have between 2 and 255 characters.")
        .matches(/^[a-zA-Z.-][ a-zA-Z.-]{1,254}$/, invalidCityError),
    state: yup.string()
        .nullable(true)
        .notRequired()
        .max(255, "City name must have between 2 and 255 characters.")
        .matches(/^[a-zA-Z.-][ a-zA-Z.-]{1,254}$/, invalidStateError)
        .transform((value, originalValue) => _.isEmpty(originalValue) ? null : value),
    postalCode: yup.string()
        .nullable(true)
        .notRequired()
        .max(15, "Postal code can contain maximum of 15 characters.")
        .matches(/^[A-Za-z0-9-][ A-Za-z0-9-]{3,15}$/, invalidPostalCodeError)
        .transform((value, originalValue) => _.isEmpty(originalValue) ? null : value),
    phone: yup.string()
        .nullable(true)
        .notRequired()
        .max(19, "Phone no. can contain maximum of 15 characters.")
        .matches(/^\+[0-9]{1,3}\.[0-9]{4,14}$/, invalidPhoneError)
        .transform((value, originalValue) => _.isEmpty(originalValue) ? null : value)
}

const GTCDataCollectionPage = () => {
    const { email, userId } = useSelector(state => state.auth);
    const { isGtcDataSubmitting, gtcSaveError, userName, userCompany } = useSelector(state => state.app);

    if (userName) {
        _.set(initialData, 'name', userName);
    }
    if (userCompany) {
        _.set(initialData, 'company', userCompany);
    }

    const schemaValidator = useMemo(() => {
        if (!userName) {
            schemaObject['name'] = yup.string()
                .required('Name is required.')
                .min(1)
                .max(255, "Full name must have between 1-255 English characters.")
                .matches(/^[a-zA-Z][ a-zA-Z]{0,254}$/, "Full name can only contain English characters.");
        }

        if (!userCompany) {
            schemaObject['company'] = yup.string()
                .required('Company is required.')
                .min(2, "Company must contain at least 2 characters.")
                .max(255, "Company must have between 2-255 English characters.")
                .matches(/^[a-zA-Z][ a-zA-Z]{1,254}$/, "Company name can only contain English characters.");
        }

        return yup.object().shape(schemaObject);
    }, [userName, userCompany]);
    
    const userSignUpForm = useForm(initialData, schemaValidator);
    const { handleFormSubmit } = userSignUpForm;

    const dispatch = useDispatch();

    const handleUserFormSubmit = () => {
        const formData = {
            email: email.trim(),
            auth0Id: userId,
            name: userSignUpForm.formState.name.trim(),
            company: userSignUpForm.formState.company.trim(),
            country: userSignUpForm.formState.country.value,
            address: userSignUpForm.formState.businessAddress.trim(),
            city: userSignUpForm.formState.city.trim()
        };

        if (userSignUpForm.formState.postalCode?.trim()) {
            formData['postalCode'] = userSignUpForm.formState.postalCode.trim();
        }
        if (userSignUpForm.formState.phone?.trim()) {
            formData['phone'] = userSignUpForm.formState.phone.trim();
        }
        if (userSignUpForm.formState.state?.trim()) {
            formData['state'] = userSignUpForm.formState.state.trim();
        }

        dispatch({
            type: "APP:SAVE-GTC-DATA",
            payload: { 
                userId,
                formData
             }
        });
    };

    return <Modal className={styles.base}>
        <ModalHeader>
            Welcome to BlueXP
        </ModalHeader>
        <ModalContent>
        <Text level="14" className={styles['modal-sub-title']}>Fill out this form to get started with BlueXP.</Text>
            <Layout.Grid>
                <Layout.GridItem lg={6}>
                    <FormFieldInputNew
                        form={userSignUpForm}
                        name="name"
                        label="Full name"
                        maxChars={256}
                        autoComplete="off"
                        style={{ marginBottom: 32 }}
                        showError={userSignUpForm.dirty.name && userSignUpForm.formErrors.name}
                        disabled={userName ? true : false}
                    />
                    <FormFieldSelect
                        form={userSignUpForm}
                        name="country"
                        label="Country"
                        placeholder="Select your country"
                        isSearchable={true}
                        options={countriesList}
                        style={{ marginBottom: 32 }}
                        showError={userSignUpForm.dirty.country && userSignUpForm.formErrors.country}
                    />
                    <FormFieldInputNew
                        form={userSignUpForm}
                        name="city"
                        label="City"
                        maxChars={256}
                        style={{ marginBottom: 32 }}
                        showError={userSignUpForm.dirty.city && userSignUpForm.formErrors.city}
                    />
                    <FormFieldInputNew
                        form={userSignUpForm}
                        isOptional={true}
                        name="postalCode"
                        label="Postal code"
                        maxChars={256}
                        showError={userSignUpForm.dirty.postalCode && userSignUpForm.formErrors.postalCode}
                    />
                </Layout.GridItem>
                <Layout.GridItem lg={6}>
                    <FormFieldInputNew
                        form={userSignUpForm}
                        name="company"
                        label="Company"
                        maxChars={256}
                        style={{ marginBottom: 32 }}
                        showError={userSignUpForm.dirty.emailId && userSignUpForm.formErrors.emailId}
                        disabled={userCompany ? true : false}
                    />
                    <FormFieldInputNew
                        label="Business address"
                        form={userSignUpForm}
                        name="businessAddress"
                        style={{ marginBottom: 32 }}
                        showError={userSignUpForm.dirty.businessAddress && userSignUpForm.formErrors.businessAddress}
                    />
                    <FormFieldInputNew
                        form={userSignUpForm}
                        isOptional={true}
                        name="state"
                        label="State"
                        maxChars={256}
                        style={{ marginBottom: 32 }}
                        showError={userSignUpForm.dirty.state && userSignUpForm.formErrors.state}
                    />
                    <FormFieldInputNew
                        form={userSignUpForm}
                        isOptional={true}
                        name="phone"
                        label="Phone"
                        placeholder="Phone (+CCC.NNNNNNNNNN)"
                        maxChars={256}
                        showError={userSignUpForm.dirty.phone && userSignUpForm.formErrors.phone}
                    />
                </Layout.GridItem>
            </Layout.Grid>
        </ModalContent>
        <ModalFooter error={gtcSaveError ? gtcSaveError : undefined}>
            <Button submit isSubmitting={isGtcDataSubmitting} onClick={handleFormSubmit(handleUserFormSubmit)}>Save</Button>
        </ModalFooter>
    </Modal>
}

export default GTCDataCollectionPage;