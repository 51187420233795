import axios from "axios";
import buildURL from 'axios/lib/helpers/buildURL';
import externals from "utils/externals";
import { apiClient } from "../clients";
import uuidv4 from "uuid/v4";
import _ from "lodash";
import { getItemAnon } from "../localstorage";

const defaultAdapter = axios.defaults.adapter;

const isAsync = getItemAnon({itemKey: `feature.enable-async`});
const isBatching = getItemAnon({itemKey: `feature.enable-batching`});

const isActive = isAsync || isBatching;
const clientId = uuidv4();
const unhandledRequestsMap = {};
const unhandledResponsesMap = {};

let pendingBatch = [];
let pendingBatchTimeout = null;

let isPollPending = false;
let previousPollResponseLength = 0;

const batchAdapter = (config) => {
    if (isActive && config.url.startsWith(externals.platformBackend) && config.method === "get" && config.headers.Accept === "application/json" && !config.noAsync) {
        const promise = new Promise((resolve, reject) => {
            const deferredRequestPromise = { resolve, reject };
            pendingBatch.push({ config, deferredRequestPromise });
        });

        if (isBatching) {
            if (!pendingBatchTimeout) {
                pendingBatchTimeout = setTimeout(() => {
                    const currentBatch = pendingBatch;
                    pendingBatch = [];
                    pendingBatchTimeout = null;
                    handleBatch(currentBatch)
                });
            }
        } else {
            const currentBatch = pendingBatch;
            pendingBatch = [];
            handleBatch(currentBatch);
        }

        return promise;
    } else {
        return defaultAdapter(config);
    }
}

const handleBatch = async (currentBatch) => {
    const requests = currentBatch.map(({ config }) => {
        const url = buildURL(
            config.url.substring(externals.platformBackend.length),
            config.params,
            config.paramsSerializer
        );

        return {
            method: "get",
            url
        }
    });

    try {
        const config = {};
        const batchResponse = await apiClient.post(`${externals.platformBackend}/async/batch`, {
            requests
        }, config);

        if(isAsync) {
            config.headers["x-netapp-async-token"] = clientId;
        }

        if (isAsync) {
            currentBatch.forEach((batchItem, index) => {
                batchItem.asyncId = batchResponse.data.asyncIds[index];
                batchItem.batchResponse = batchResponse;
                unhandledRequestsMap[batchItem.asyncId] = batchItem;
            });
            matchUnhandledResponses();
            safeStartPolling();
        } else {
            currentBatch.forEach((batchItem, index) => {
                batchItem.batchResponse = batchResponse;
                const immediateResponse = batchResponse.data.responses[index];

                handleResponse(batchItem, immediateResponse);
            });
        }
    } catch (batchError) {
        console.log("Original Batch Error", batchError);
        currentBatch.forEach(({ deferredRequestPromise }) => {
            const individualError = new Error("Batch API Error");
            individualError.response = batchError.response;
            deferredRequestPromise.reject(individualError);
        })
    }
}

const safeStartPolling = async () => {
    if (!_.isEmpty(unhandledRequestsMap) && !isPollPending) {
        isPollPending = true;

        try {
            const { data: deferredResponses } = await apiClient.get(`${externals.platformBackend}/async/${clientId}?last=${previousPollResponseLength}`, {
                noAsync: true
            });
            isPollPending = false;

            deferredResponses.forEach(({ id: asyncId, response: deferredResponse }) => {
                const unhandledRequest = unhandledRequestsMap[asyncId];
                if (unhandledRequest) {
                    delete unhandledRequestsMap[asyncId];
                    handleResponse(unhandledRequest, deferredResponse)
                } else {
                    unhandledResponsesMap[asyncId] = deferredResponse;
                }
            })

            previousPollResponseLength = deferredResponses.length;

            safeStartPolling();
        } catch (e) {
            console.error(`Error polling Batched Async responses`, e);
            isPollPending = false;

            setTimeout(safeStartPolling, 30 * 1000)
        }

    }
}

const handleResponse = ({ batchResponse, config, deferredRequestPromise }, deferredResponse) => {
    const resolvedResponse = { ...batchResponse };

    resolvedResponse.config = config;

    const realResponse = deferredResponse;
    resolvedResponse.data = realResponse.content;
    resolvedResponse.headers = realResponse.headers;
    resolvedResponse.status = realResponse.status;

    if (resolvedResponse.status >= 200 && resolvedResponse.status <= 300) {
        deferredRequestPromise.resolve(resolvedResponse);
    } else {
        const e = new Error("API Error");
        e.response = resolvedResponse;
        deferredRequestPromise.reject(e);
    }
}

const matchUnhandledResponses = () => {
    const unmatchedAsyncIds = _.keys(unhandledResponsesMap);
    _.forEach(unmatchedAsyncIds, asyncId => {
        const unhandledRequest = unhandledRequestsMap[asyncId];
        if(unhandledRequest) {
            const unhandledResponse = unhandledResponsesMap[asyncId];
            delete unhandledRequestsMap[asyncId];
            delete unhandledResponsesMap[asyncId];
            handleResponse(unhandledRequest, unhandledResponse)
        }
    });
}

export default batchAdapter;