import _ from "lodash";

export const actionStatus = {
    PENDING: "PENDING",
    FAILURE: "FAILURE",
    SUCCESS: "SUCCESS",
    UNSET: "UNSET"
};

const statusWeight = {
    SUCCESS: 0,
    PENDING: 1,
    FAILURE: 2,
    UNSET: -1
}

export const resolveStatuses = selectors => state => {
    const selectorsArr = _.isArray(selectors) ? selectors : [selectors];
    return _.reduce(selectorsArr, (finalStatus, selector) => {
        const actionState = selector(state);
        return statusWeight[finalStatus.status] > statusWeight[actionState.status] ? finalStatus : actionState;
    }, {status: actionStatus.UNSET, resolved: false});
};

export const selectorOf = ({key, uniqueId}) => state => {
    const actionKey = uniqueId ? `${key}:${uniqueId}` : key;
    return state.actions[actionKey] || {status: actionStatus.UNSET, resolved: false};
};
