import { useDispatch } from "react-redux";

export default () => {

    const dispatch = useDispatch()

    dispatch({
        type: "NOTIFICATIONS:ERROR",
        payload: {
            message: "Something went wrong while redirecting you, try again",
            key: "APP-REDIRECT",
            showGlobal: true
        }
    })

    return <></>
}