import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./../MainHeader.module.scss";
import SlidingWidget, { CaptionButton, SlidingWidgetFooter } from "./SlidingWidget"
import { Checkbox, StyledLink } from "@netapp/bxp-design-system-react";
import classNames from "classnames";
import ListMenuContent from "./ListMenuContent";

const ChangeAccountButton = ({ index, style, data: {accounts, setSelectedAccount, selectedAccount, activeAccount} }) => {
    const dispatch = useDispatch();
    const account = accounts[index];
    const isSelected = selectedAccount === account;
    const isActive = activeAccount === account;
    const permissions = useSelector(state => state.tenancy.permissions);

    return <Checkbox style={style} disabled={process.env.REACT_APP_IS_SAAS !== "true"} className={classNames(styles['menu-content-list-button'], styles['menu-content-link-button'], {[styles['menu-content-link-button-active']]: isSelected})} onChange={() => {
        if(isSelected) {
            return;
        }

        setSelectedAccount(account);
    }} checked={isSelected} ariaLabel={account.accountName}>
        <span>{account.accountName}</span>
        { permissions.editAccount && isActive && <StyledLink
            variant="text"
            style={{margin: "0px 5px 0px auto"}}
            to={location => ({...location, hash: "accountManagement"})}
            onClick={() => dispatch({type: "MENU:CLOSE-WIDGET"})}
        >
            {process.env.REACT_APP_IS_DARK === "true" ? "Manage Users" : "Manage Account"}
        </StyledLink> }
    </Checkbox>
};

const AccountMenuFooter = ({activeAccount, selectedAccount}) => {
    const dispatch = useDispatch();

    return <SlidingWidgetFooter onSwitch={() => {
        if(activeAccount === selectedAccount){
            return;
        }

        dispatch({
            type: "TENANCY:SET-ACCOUNT",
            payload: {
                accountId: selectedAccount.accountPublicId
            }
        });
    }} onClose={() => {
        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }}/>
};

const MenuContent = React.memo(({setSelectedAccount, selectedAccount}) => {
    const activeAccount = useSelector(state => state.tenancy.selectedAccount);
    const accounts = useSelector(state => state.tenancy.accounts) || [];
    const accountStatus = useSelector(state => state.tenancy.accountStatus);
    return <div style={{paddingTop: 35}}>
        <ListMenuContent
            itemSize={59}
            label="accounts"
            heightOffset={260 - (process.env.REACT_APP_IS_SAAS === "true" ? 0 : 65)}
            Item={ChangeAccountButton}
            status={accountStatus}
            activeItem={activeAccount}
            selectedItem={selectedAccount}
            setSelectedItem={setSelectedAccount}
            items={accounts}
            itemData={{accounts, setSelectedAccount, selectedAccount, activeAccount}}
            scrollAlign="start"
        />
    </div>
});

export default () => {
    const activeAccount = useSelector(state => state.tenancy.selectedAccount);
    const [selectedAccount, setSelectedAccount] = useState(activeAccount);
    const accountName = activeAccount?.accountName || "N/A";
    return <SlidingWidget
        widgetKey="accounts"
        menuTitle="Accounts"
        triggerClassName={styles['caption-button']}
        triggerActiveClassName={[styles['caption-button-active']]}
        TriggerButton={(props) => <CaptionButton title="Account" value={accountName} {...props}/>}
        MenuFooter={process.env.REACT_APP_IS_SAAS !== "true" ? undefined : () => <AccountMenuFooter activeAccount={activeAccount} selectedAccount={selectedAccount}/>}
    >
        <MenuContent setSelectedAccount={setSelectedAccount} selectedAccount={selectedAccount}/>
    </SlidingWidget>
};
