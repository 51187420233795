import { Notifications } from "@netapp/bxp-design-system-react"
import { useSelector, useDispatch } from "react-redux";
import React, { useMemo } from "react";

export default React.memo(() => {
    const _notifications = useSelector(state => state.notifications.notifications);
    const dispatch = useDispatch();
    const notifications = useMemo(() => {
        return _notifications.map(notification => {
            if(!notification.isFatal) {
                return {...notification, key: notification.notificationId, onClose: () => {
                    dispatch({
                        type: "NOTIFICATIONS:REMOVE",
                        payload: {
                            notificationId: notification.notificationId
                        }
                    })
                }}
            } else {
                return {...notification, key: notification.notificationId};
            }
        });
    }, [_notifications, dispatch]);

    return <Notifications notifications={notifications} isGlobal={true}/>
})
