import styles from "./NotificationMenu.module.scss";
import { Span, PopoverMechanism, CheckboxFilter } from "@netapp/bxp-design-system-react";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import _ from "lodash";

const FilterServicesContent = ({ close }) => {
    const filterState = useSelector(state => state.uiNotifications.servicesFilter);
    const notifications = useSelector(state => state.uiNotifications.notifications);
    const services = useSelector(state => state.app.services);
    const dispatch = useDispatch();

    const filterOptions = useMemo(() => {
        return _.chain(notifications).map("service").uniq().value().map(service => {
            return {
                label: (services[service]?.name || service) + ` (${notifications.filter(n => n.service === service).length})`,
                value: service
            }
        });
    }, [services, notifications])

    return <CheckboxFilter filterOptions={filterOptions} filterState={filterState} close={(e) => {
        e.stopPropagation();
        close();
    }} updateFilter={(values) => {
        dispatch({
            type: "UI-NOTIFICATIONS:SET-FILTER",
            payload: {
                id: "services",
                values
            }
        })
    }}/>
};

const Caret = ({isActive}) => {
    return <span className={classNames(styles['caret'], {[styles['active']]: isActive})}/>
};

export const FilterServices = React.memo(() => {
    const filterState = useSelector(state => state.uiNotifications.servicesFilter);

    return <PopoverMechanism
        Popover={FilterServicesContent}
        Trigger={({isActive}) => <><Span bold>Filter Services ({!filterState.activeCount ? "All" : filterState.activeCount})</Span><Caret isActive={isActive}/></>}
        triggerOnClick={true}
        renderPosition="left"
        popoverClassName={styles['filter-popover']}
    />
})

const typeOptions = [
    { label: "Information", value: "info" },
    { label: "Success", value: "success" },
    { label: "Warning", value: "warning" },
    { label: "Error", value: "error" },
    { label: "Critical", value: "critical" , disabled:true },
    { label: "Recommendation", value: "recommendation" }
];

const typeOptionsCountered = (notifications) => typeOptions.map(type => ({
    label: type.label + ` (${notifications.filter(n => n.priority === type.value).length})`,
    value: type.value,
    disabled:type.disabled

}))

const FilterTypeContent = ({ close }) => {
    const notifications = useSelector(state => state.uiNotifications.notifications);
    const filterState = useSelector(state => state.uiNotifications.typeFilter);
    const dispatch = useDispatch();
    return <CheckboxFilter filterOptions={typeOptionsCountered(notifications)} filterState={filterState} close={(e) => {
        e.stopPropagation();
        close();
    }} updateFilter={(values) => {
        dispatch({
            type: "UI-NOTIFICATIONS:SET-FILTER",
            payload: {
                id: "type",
                values
            }
        })
    }}/>
}

export const FilterType = React.memo(() => {
    const filterState = useSelector(state => state.uiNotifications.typeFilter);

    return <PopoverMechanism
        Popover={FilterTypeContent}
        Trigger={({isActive}) => <><Span bold>Filter Type ({!filterState.activeCount ? "All" : filterState.activeCount})</Span><Caret isActive={isActive}/></>}
        triggerOnClick={true}
        renderPosition="left"
        popoverClassName={styles['filter-popover']}
    />
})
