import React, { useEffect } from "react";
import styles from "./NoAccessComponents.module.scss";
import { ReactComponent as ForbiddenIcon } from "./forbidden.svg";
import externals from "./../../../utils/externals";
import { TabHeader, Button, Card, Layout, Heading, Text,ExternalLink,Span } from "@netapp/bxp-design-system-react";
import { SecurityIcon, ConnectorDarkIcon as ConnectorIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as ComingSoonIcon } from "./coming-soon.svg";
import { ReactComponent as UpgradeRequiredIcon } from "./upgrade-required.svg";
import { isNewTenancyEnabled } from "utils/localstorage";

export const Forbidden = () => {
    return <section className={styles.container} style={{ paddingTop: 152 }}>
        <ForbiddenIcon className={styles.forbidden}/>
        <Heading>Contact the administrator of this system to gain access</Heading>
        <Text>Before you can use this connector, the administrator must add your user to the BlueXP Project.</Text>
        <ExternalLink trackCategory="NoAccessPages" href={externals.saasFrontend} newTab large>Go to BlueXP</ExternalLink>
    </section>
};

export const SaaSDisabled = () => {
    const isTenancyV4 = isNewTenancyEnabled();
    return <section className={styles.container} style={{ paddingTop: 152 }}>
        <ForbiddenIcon className={styles.forbidden}/>
        <Heading color="text-title">Your {isTenancyV4 ? 'organization' : 'account'} does not have access to this interface.</Heading>
        <Text>Your BlueXP Project has been disabled for use with the SaaS interface.</Text>
        <Text>If you have any questions, reach out to us by using the chat feature below.</Text>
    </section>
};

export const ServiceNeedsSaaSIsDisabled = ({ needHeader, currentService: { logo, service: { label } } }) => {
    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <section className={styles.container}>
            <SecurityIcon size={82}/>
            <Heading className={styles['not-reachable']}>This service isn't supported when SaaS is disabled on your account.</Heading>
            <Text>Your Cloud Central account has been disabled for use with the SaaS interface.</Text>
            <Text>If you have any questions, reach out to us by using the chat feature below.</Text>
        </section>
    </>
};

export const ServiceNeedsThirdPartyIsDisabled = ({ needHeader, currentService: { logo, service: { label } } }) => {
    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <section className={styles.container}>
            <SecurityIcon size={82}/>
            <Heading className={styles['not-reachable']}>This is a third party service, it requires third-party services to be enabled in your account.</Heading>
            <Text>Third party services are disabled in your account.</Text>
            <Text>If you have any questions, reach out to us by using the chat feature below.</Text>
        </section>
    </>
};

export const NoContainerMessage = ({ needHeader, currentService: { logo, service: { label } } }) => {
    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <Layout.Container>
            <Layout.Grid centerContent>
                <Layout.GridItem lg={6}>
                    <Card className={styles["card-container"]}>
                        <ConnectorIcon color='text' style={{marginTop:6, width: 40, height: 28}}/>
                        <Heading level={2} style={{marginTop:14}}>Something went wrong</Heading>
                        <Text style={{marginTop:16}}>There's a problem with your Connector, but your stored data is not affected. Open a case with NetApp support to fix the issue.</Text>
                    </Card>
                </Layout.GridItem>
            </Layout.Grid>
        </Layout.Container>
    </>
};

export const PullingContainerMessage = ({ needHeader, currentService: { logo, service: { label } } }) => {
    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <Layout.Container>
            <Layout.Grid centerContent>
                <Layout.GridItem lg={6}>
                    <Card className={styles["card-container"]}>
                        <ConnectorIcon color='text' style={{marginTop:6, width: 40, height: 28}}/>
                        <Heading level={2} style={{marginTop:14}}>Something went wrong</Heading>
                        <Text style={{marginTop:16}}>BlueXP is preparing the Connector. Please wait a few moments.</Text>
                    </Card>
                </Layout.GridItem>
            </Layout.Grid>
        </Layout.Container>
    </>
};

export const UpgradeRequired = ({ needHeader, currentService: { logo, service: { label } } }) => {
    const dispatch = useDispatch();
    const provider = useSelector(state => state.tenancy.selectedAgent?.provider)

    let type = "instance type"

    if (provider === "gcp") {
        type = "machine type";
    } else if (provider === "azure") {
        type = "VM type";
    } else if (provider === "on-prem") {
        type = "VM"
    }

    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <section className={styles.container} style={{ paddingTop: 60 }}>
            <UpgradeRequiredIcon style={{ marginBottom: 13 }}/>
            <Heading color="error" bold><Span style={{ textTransform: "capitalize" }}>{type}</Span> change is required.</Heading>
            <Text style={{ maxWidth: 485, textAlign: "center" }}>You need to change the {type} for your Connector so you can enjoy new capabilities that you’re currently missing.</Text>
            <Button style={{ width: 152, marginTop: 32 }} large onClick={() => {
                dispatch({
                    type: "CURRENT-CONNECTOR:OPEN-WEAK-CONNECTOR-DIALOG",
                    track: "from-no-access-page"
                })
            }}>Learn More</Button>
        </section>
    </>
};

export const NoLocalMessage = ({ needHeader, currentService: { logo, service: { label } } }) => {
    const location = useLocation();
    const selectedAccountId = useSelector(state => state.tenancy.selectedAccountId);
    const connectorId = useSelector(state => state.tenancy.selectedAgentId);
    const connectorIdSuffix = connectorId ? `&connectorId=${connectorId}` : "";
    const url = `${externals.saasFrontend}${location.pathname}?accountId=${selectedAccountId}${connectorIdSuffix}`;

    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <section className={styles.container}>
            <SecurityIcon size={82}/>
            <Heading className={styles['not-reachable']}>This service is only accessible from BlueXP.</Heading>
            <Text>Visit the BlueXP UI to use this service.</Text>
            <ExternalLink
                large
                href={url}
                newTab
            > Go to BlueXP</ExternalLink>
        </section>
    </>
};

export const ComingSoonMessage = ({ needHeader, currentService: { logo, service: { label } } }) => {
    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <section className={styles.container}>
            <ComingSoonIcon className={styles['coming-soon']}/>
            <Heading>We can’t access this service right now.</Heading>
            <Text>This service is not yet supported with the cloud provider where the Connector is deployed.</Text>
        </section>
    </>
};

export const NoPermissionMessage = ({ needHeader, currentService }) => {
    const { logo, service: { label } = {} } = currentService || {};
    return <>
        {currentService && needHeader ? <TabHeader logo={logo} label={label}/> : ''}
        <section className={styles.container}>
            <SecurityIcon size={82}/>
            <Heading className={styles['not-reachable']}>Your user does not have the required permissions for this service.</Heading>
        </section>
    </>
};

export const NeedSaaSRedirect = React.memo(({ needHeader, currentService: { logo, service: { label } } }) => {
    const location = useLocation();
    const selectedAccountId = useSelector(state => state.tenancy.selectedAccountId);
    const connectorId = useSelector(state => state.tenancy.selectedAgentId);

    const url = `${externals.saasFrontend}${location.pathname}?accountId=${selectedAccountId}&connectorId=${connectorId}`;

    useEffect(() => {
        window.open(url, "_blank")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {needHeader && <TabHeader logo={logo} label={label}/>}
        <section className={styles.container}>
            <SecurityIcon size={82}/>
            <Heading className={styles['not-reachable']}>Service requires a secure connection.</Heading>
            <Text>This service is only accessible from BlueXP or local UI served over HTTPS.</Text>
            <ExternalLink
                large
                href={url}
                newTab
            > Go to BlueXP</ExternalLink>
        </section>
    </>
});
