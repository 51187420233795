import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import styles from "../MainHeader.module.scss";
import React from "react";
import classNames from "classnames";

export default ({to, children, disabled}) => {
    const dispatch = useDispatch();

    return <NavLink
        to={to}
        className={classNames(styles['menu-content-link-button'], {[styles['menu-content-link-button-disable']]: disabled})}
        activeClassName={styles['menu-content-link-button-active']}
        onClick={() => {
                    dispatch({
                        type: "MENU:CLOSE-WIDGET"
                    })

        }}
    >{children}</NavLink>
};
