import { apiClient } from 'utils/clients';
import externals from "utils/externals";

export default ({dispatch}) => next => async action => {
    const { type, payload } = action;
    if (type === "USER:UPDATE-USERNAME") {
         apiClient.post(`${externals.portalBackend}/portal/update-user-info`, { userName: payload.userName })
            .then(() => {
                dispatch({
                    type: "USER:UPDATE-USERNAME-SUCCESS",
                    payload: { userName: payload.userName }
                })
            })
            .catch(error => {
                dispatch({
                    type: "USER:UPDATE-USERNAME-ERROR",
                });
            });
    }
    return next(action);
};