let userId = "anon";
let tenancyId = "-";
let orgId = "-";
let isGovCloud = false;

const generateKey = ({ itemKey, userId, includeTenancyId }) => includeTenancyId ? `occm.${itemKey}.${userId}.${tenancyId}` : `occm.${itemKey}.${userId}`;

const get = (k) => {
    try {
        return JSON.parse(window.localStorage.getItem(k));
    } catch (e) {
        return null;
    }
}

const set = (k, v) => {
    window.localStorage.setItem(k, JSON.stringify(v));
}

const remove = (k) => {
    window.localStorage.removeItem(k);
}

export const getItem = ({ itemKey, includeTenancyId }) => {
    return get(generateKey({ itemKey, userId, includeTenancyId }));
};

export const setItem = ({ itemKey, includeTenancyId }, value) => {
    set(generateKey({ itemKey, userId, includeTenancyId }), value);
};

export const removeItem = ({ itemKey, includeTenancyId }) => {
    remove(generateKey({ itemKey, userId, includeTenancyId }));
};

export const getItemAnon = ({ itemKey }) => {
    return get(generateKey({ itemKey, userId: "anon" }));
};

export const setItemAnon = ({ itemKey }, value) => {
    set(generateKey({ itemKey, userId: "anon" }), value);
};

export const removeItemAnon = ({ itemKey }) => {
    remove(generateKey({ itemKey, userId: "anon" }));
};

export const setUserId = ({ userId: _userId }) => {
    userId = _userId;
};

export const setTenancyId = ({ tenancyId: _tenancyId }) => {
    tenancyId = _tenancyId;
}

export const setOrgId = ({ orgId: _orgId }) => {
    orgId = _orgId;
}

export const setGovCloud = (_isGovCloud) => {
    isGovCloud = _isGovCloud;
}

export const isNewTenancyEnabled = () => {
    return process.env.REACT_APP_IS_DARK !== "true" && !isGovCloud && !getItem({ itemKey: `feature.NetApp.TenancyV2/*`, includeTenancyId: false });
}
