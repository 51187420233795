import React from "react";
import { Layout, Heading, TabHeader, Text, Button } from "@netapp/bxp-design-system-react";
import { trackEvent } from "utils/mrTracker";
import { useLocation } from "react-router";

class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        trackEvent({ category: "AppException", action: "MainErrorBoundary", label: error.stack || error.message });
        console.error(error);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.children !== nextProps.children || this.state.hasError !== nextState.hasError || this.state.hasError && this.props.pathname !== nextProps.pathname;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.hasError && prevProps.pathname !== this.props.pathname) {
            this.setState({ hasError: false })
        }
    }

    render() {
        if (this.state.hasError) {
            return <Layout.Page>
                <TabHeader label="Failed to Render"/>
                <Layout.Content style={{ padding: "40px 0" }}>
                    <Layout.Column>
                        <Heading level={2}>
                            The tab or service has unexpectedly crashed.
                        </Heading>
                        <Text>
                            Error printed to console.
                        </Text>
                        <Text>If the error recurs please contact Support.</Text>

                        {process.env.NODE_ENV === "development" && <Button color="secondary" onClick={() => {
                            this.setState({ hasError: false })
                        }}>
                            Reset
                        </Button>}
                    </Layout.Column>
                </Layout.Content>
            </Layout.Page>;
        }

        return this.props.children;
    }
}

const MainErrorBoundary = React.memo(({ children }) => {
    const location = useLocation();

    return <ErrorHandler pathname={location.pathname}>
        {children}
    </ErrorHandler>
});

export default MainErrorBoundary;