import { occmClient } from "utils/clients";
import {pushNgMessage} from "../NgWrapper";


export const getTaskStatus = (taskId) => {
    return occmClient.get(`/occm/api/audit/activeTask/${taskId}`)
        .then(res => res.data);
};

const taskPolling = async (taskId) =>  {
    const taskStatus = await getTaskStatus(taskId);
    pushNgMessage({
        type: "Polling-Status-Update",
        payload: taskStatus
    });
    if(taskStatus.status === 0) {
        setTimeout(() => taskPolling(taskId), 5000);
    }
};


export default ({dispatch, getState}) => next => async action => {
    const { type, payload } = action;
        if (type === "POLLER:INITIATE-POLL") {
        taskPolling(payload.taskId);
    }
    return next(action);
};
