import querystring from 'query-string';
import { matchPath } from "react-router";
import _ from 'lodash';
import trackingAggregationPaths from "./../trackingAggregationPaths.config";

const EXTERNAL_LINK_ACTION = "ExternalLinkClick";

const keepQueryParamValues = _.keyBy(["view"]);
const aggregatePaths = _.flatten(trackingAggregationPaths.map(path => {
    const lastSlash = _.lastIndexOf(path,"/");
    const lastColon = _.lastIndexOf(path, ":");

    if(lastColon > lastSlash) {
        return [path + "/*", path]
    } else {
        return [path];
    }
}));

let lastPath = null;

export const aggregatePath = ({ location }) => {
    let rPath = location.pathname;
    if (location.hash && !location.hash.includes("access_token")) {
        rPath = rPath + location.hash;
    }

    _.forEach(aggregatePaths, path => {
        const match = matchPath(location.pathname, {
            path
        });

        if (match) {
            if (match.path.includes("*")) {
                rPath = match.path;
                let index = 0;
                while(rPath.includes("*")) {
                    rPath = rPath.replace(/\*/, match.params[index.toString()]);
                    index = index + 1;
                }
            } else {
                rPath = match.path;
            }

            return false;
        }
    })

    if (location.search) {
        const search = querystring.parse(location.search);

        delete search.paramsForLoginPage;
        delete search.__hstc;
        delete search.__hssc;
        delete search.__hsfp;
        delete search.hsCtaTracking;
        delete search.mkt_tok;
        delete search.code;

        const searchString = querystring.stringify(_.mapValues(search, (v, k) => {
            if (keepQueryParamValues[k]) {
                return v;
            } else {
                return "-";
            }
        }));
        rPath = rPath + (searchString ? "?" + searchString : '');
    }

    return rPath;
};

export const trackPageView = ({ location }) => {
    let path = location.pathname + location.hash;

    if (lastPath === path) {
        return;
    }

    lastPath = path;

    window.gtag('event', 'page_view', {
        page_path: aggregatePath({ location })
    });
};

export const trackTiming = ({ category, name, value = performance.now() }) => {
    window.gtag('event', 'timing_complete', {
        name,
        'value': Math.round(value),
        'event_category': category
    });
};

export const trackExternalLink = ({ category, label }) => {
    window.gtag('event', EXTERNAL_LINK_ACTION, {
        'event_category': category,
        'event_label': label
    });
};

export const trackEvent = ({ category: event_category, action, label: event_label, value }) => {
    window.gtag('event', action, {
        event_category,
        event_label,
        value
    });
};
