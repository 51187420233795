import styles from "./NotificationMenu.module.scss";
import { Card, ComponentLoader, Span, StyledLink, TransitionChevron, Button, height, ExternalLink } from "@netapp/bxp-design-system-react";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useMemo } from "react";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import { ErrorIcon, NoticeTriangleIcon, SuccessIcon, InfoIcon, LightBulbIcon, RemoveDarkIcon as RemoveIcon, CriticalNotificationIcon, BellIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { UnmountClosed } from 'react-collapse';
import { FilterServices, FilterType } from "./NotificationsFilters";
import _ from "lodash";

const iconsMap = {
    "warning": NoticeTriangleIcon,
    "error": ErrorIcon,
    "info": InfoIcon,
    "success": SuccessIcon,
    "recommendation": LightBulbIcon,
    "critical": CriticalNotificationIcon
}

const DismissingAllOverlay = () => {
    return <div className={styles['dismissing-all']}>
        <ComponentLoader style={{ marginBottom: 14 }}/>
        <Span level={14} bold>Dismissing Notifications</Span>
    </div>
}

const Notification = ({ label, priority, timestamp, serviceName, stored, service, id, cta, descriptions, link }) => {
    const dispatch = useDispatch();
    const isDescriptionAlwaysOpen = priority === "critical"
    const [isDescriptionOpen, setDescriptionOpen] = useState(isDescriptionAlwaysOpen);
    let Icon = iconsMap[priority];
    return <Card key={id} className={styles[`notification-container-${priority}`]}>
        <div className={classNames(styles[`icon-container-${priority}`])}>
            <Icon className={classNames(styles['priority-icon'], styles[`priority-icon-${priority}`], styles[priority])}/>
        </div>
        <div>

            <div title={label} className={classNames(styles['label'])}>
                <div className={styles[priority]}>
                    <span>{label}</span>
                </div>
            </div>
            <UnmountClosed isOpened={isDescriptionOpen} theme={{ collapse: height, content: styles['descriptions'] }}>
                {descriptions.map((description) => <p title={description} className={styles["p-desc"]} key={description}><Span>{description}</Span></p>)}
                {link?.url && <div className={styles['link-container']}><ExternalLink variant="text" href={link.url} includeArrow={true} onClick={() => {
                    dispatch({
                        type: "TRACKING",
                        payload: {
                            category: "UiNotifications",
                            action: "FlowAction:LinkClick",
                            label: link.url,
                        }
                    })

                }}> {link.label} </ExternalLink></div>}
            </UnmountClosed>
            {cta?.label && <div>
                <StyledLink title={cta.label} className={styles['cta']} color="secondary" to={{ pathname: cta.to, state: cta.params }} onClick={() => {
                    dispatch({
                        type: "TRACKING",
                        payload: {
                            category: "UiNotifications",
                            action: "FlowAction:CallToActionClick",
                            label,
                        }
                    })
                    dispatch({
                        type: "MENU:CLOSE-WIDGET"
                    })
                }}>{cta.label}</StyledLink>
            </div>}
            <div className={styles['notification-info']}>
                <div><span className={styles['value']}>{formatDistanceToNow(timestamp)} ago</span><span title={serviceName || service} className={styles['value']}>{serviceName || service}</span></div>
                <Button className={styles['dismiss-one']} type="text" onClick={(e) => {
                    dispatch({
                        type: "UI-NOTIFICATIONS:DISMISS-ONE",
                        payload: {
                            id,
                            stored
                        },
                        track: label
                    })
                    e.stopPropagation();
                }}><RemoveIcon className={classNames(styles['dismiss-one-icon'])}/>Dismiss</Button>
            </div>
        </div>
        <div>
            {(descriptions && descriptions.length > 0 && !isDescriptionAlwaysOpen) && <button onClick={() => {
                setDescriptionOpen(!isDescriptionOpen)
                const flowAction = !isDescriptionOpen ? "FlowAction:OpenDescription" : "FlowAction:CloseDescription"
                dispatch({
                    type: "TRACKING",
                    payload: {
                        category: "UiNotifications",
                        action: flowAction,
                        label,
                    },
                })

            }}><TransitionChevron className={styles['chevron']} medium isActive={isDescriptionOpen} color="text"/></button>}
        </div>
    </Card>
}

const NotificationsContent = React.memo(() => {
    const notifications = useSelector(state => state.uiNotifications.notifications);
    const servicesFilter = useSelector(state => state.uiNotifications.servicesFilter);
    const typeFilter = useSelector(state => state.uiNotifications.typeFilter);
    const isDismissingAll = useSelector(state => state.uiNotifications.isDismissingAll);
    const services = useSelector(state => state.app.services);
    const dispatch = useDispatch();

    const filteredNotifications = useMemo(() => {
        if (!typeFilter.activeCount && !servicesFilter.activeCount) {
            return notifications;
        }

        return _.filter(notifications, notification => {
            const matchesType = !typeFilter.activeCount || typeFilter.values[notification.priority] || typeFilter.values['all']
            const matchesService = !servicesFilter.activeCount || servicesFilter.values[notification.service] || servicesFilter.values['all']
            return matchesType && matchesService;
        })
    }, [notifications, typeFilter, servicesFilter]);

    if (notifications.length === 0) {
        return <div className={styles['no-notifications']}>
            <BellIcon color="disabled" size={36} style={{ marginBottom: 14 }}/>
            <Span level={14} bold>No new notifications (for this session)</Span>
        </div>
    }

    return <div className={styles['content']}>
        {isDismissingAll && <DismissingAllOverlay/>}
        <div className={styles["widgets"]}>
            <FilterServices/>
            <FilterType/>
            {(typeFilter.activeCount > 0 || servicesFilter.activeCount > 0) && <Button type="text" onClick={(e) => {
                dispatch({
                    type: "UI-NOTIFICATIONS:CLEAR-FILTERS"
                })
                e.stopPropagation();
            }}>Clear Filters</Button>}
        </div>
        {filteredNotifications.length === 0 && <div className={styles['no-notifications']}>
            <BellIcon color="disabled" size={36} style={{ marginBottom: 14 }}/>
            <Span level={14} bold>No notifications for current filter</Span>
        </div>}
        {filteredNotifications.length > 0 && <div className={styles["notifications"]}>
            {filteredNotifications.map(notification => <Notification key={notification.id} {...notification} serviceName={services[notification.service]?.name}/>)}
        </div>}
    </div>
})

export default NotificationsContent;