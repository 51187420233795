import {handleApiAction} from "utils/actions";
import {externalClient} from "utils/clients";

export default ({getState}) => next => async action => {
    const {type} = action;

    if (type === "COMMON:FETCH-MY-IP") {
        if(!getState().common.myIp) {
            handleApiAction(action, () => externalClient.get('https://api.ipify.org?format=json'))
        }
    }

    return next(action);
}
