import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import WeakConnectorBanner from "./WeakConnectorBanner";
import ManualWeakConnectorUpgradeDialog from "./ManualWeakConnectorUpgradeDialog";
import useCurrentService from "../../../utils/useCurrentService";
import _ from "lodash";

export default React.memo(() => {
    const dispatch = useDispatch();
    const selectedAgentId = useSelector(state => state.tenancy.selectedAgentId);
    const isWeakConnector = useSelector(state => !state.tenancy.selectedAgent?.agent?.useDockerInfra);
    const hideWeakConnectorBanner = useSelector( state => state.currentConnector.hideWeakConnectorBanner);
    const isWeakConnectorDialogOpen = useSelector( state => state.currentConnector.isWeakConnectorDialogOpen);
    const currentService = useCurrentService();
    const currentServiceNeedsInfraKey = currentService && _.get(currentService, "service.agent");

    useEffect(() => {
        dispatch({
            type: "CURRENT-CONNECTOR:INIT-CONNECTOR"
        })
    }, [selectedAgentId, dispatch])

    if (!currentServiceNeedsInfraKey && !hideWeakConnectorBanner && isWeakConnector && !isWeakConnectorDialogOpen && selectedAgentId && process.env.REACT_APP_IS_DARK !== "true") {
        return <WeakConnectorBanner/>
    } else if (isWeakConnectorDialogOpen) {
        return <ManualWeakConnectorUpgradeDialog/>
    }

    return "";
});
