import axios from "axios";
import { AsyncPoller } from "./http/asyncPoller";
import { getItemAnon, getItem } from "./localstorage";
import batchAdapter from "./http/batchAdapter"

export const externalClient = axios.create();

export const occmClient = axios.create({
    baseURL: process.env.REACT_APP_OCCM_ENDPOINT,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    adapter: process.env.REACT_APP_IS_SAAS === "true" ? batchAdapter : undefined
});

export const forwarderClient = axios.create({
    baseURL: process.env.REACT_APP_FORWARDER_BACKEND,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    adapter: process.env.REACT_APP_IS_SAAS === "true" ? batchAdapter : undefined
});

const isNewAsync = getItemAnon({itemKey: `feature.enable-async`});
const isNewBatching = getItemAnon({itemKey: `feature.enable-batching`});
const isDisableAsync = isNewAsync || isNewBatching || getItemAnon({itemKey: `feature.disable-async`});


let asyncPoller = null;
if(!isDisableAsync) {
    asyncPoller = new AsyncPoller(occmClient);
}

export const pauseAsyncPoller = () => {
    if(asyncPoller) {
        asyncPoller.pause();
    }
}

export const apiClient = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    adapter: process.env.REACT_APP_IS_SAAS === "true" ? batchAdapter : undefined
});

export const downloadCSVClient = axios.create({
    headers: {
        "Content-Type": "text/csv",
        "Accept": "text/csv"
    }
});

export const setExtentedUsersHeaders = (extededHeader) => {
    apiClient.defaults.headers['Accept'] = extededHeader;
};

export const getOccmConfigForAgent = (agentId) => {
    const config = occmClient.defaults
    return { baseURL: config.baseURL, headers: {...config.headers, "X-Agent-Id": agentId}, adapter: config.adapter}
};

export const setOccmAgent = ({agentId, accountId}) => {
    occmClient.defaults.headers['X-Agent-Id'] = agentId;
    occmClient.defaults.headers['X-Tenancy-Account-Id'] = accountId;
};

export const setForwarderAgent = ({agentId, accountId}) => {
    forwarderClient.defaults.headers['X-Agent-Id'] = agentId;
    forwarderClient.defaults.headers['X-Tenancy-Account-Id'] = accountId;
}

export const setAccessToken = (accessToken) => {
    apiClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    occmClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    forwarderClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    downloadCSVClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
};

export const recursiveGet = async (url, config) => {
    let hasMoreData = true;
    const returnValue = [];
    let count = 0;

    while (hasMoreData) {
        const { data } = await apiClient.get(url, config);
        hasMoreData = !!data.next;
        count = data.count
        if(data.next) {
            config = config || {};
            config.params = config.params || {};
            config.params.nextToken = data.next;
        }
        returnValue.push(...data.items)
    }

    return {
        data: {
            count,
            items: returnValue,
            next: null
        }
    };
};
