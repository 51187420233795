import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import uuidv4 from "uuid/v4";

const noListener = () => false;
export default ({ iframeUrl, listener = noListener, shouldListen = true, isThirdParty, trackKey, subTabs, featureId }) => {
    const iframeRef = useRef(null);
    const isReadyRef = useRef(false)
    const [readyState, setReadyState] = useState(null);
    const initializationData = useRef({});
    const iframeOrigin = iframeUrl && new URL(iframeUrl, iframeUrl.startsWith("http") ? undefined : window.location.origin).origin;
    const history = useHistory();

    const apiResponses = useSelector(state => state.occmProxy?.responses);
    const { idTokenPayload: userMetadata, accessToken, thirdPartyToken } = useSelector(state => state.auth);
    const { selectedAgent: connectorAgent, selectedAccountId: accountId, selectedWorkspaceId: workspaceId, isDemo: isDemoMode, selectedOrgId: organizationId, selectedScopeId: scopeId } = useSelector(state => state.tenancy);
    const connectorId = connectorAgent?.agentId;
    const connectorName = connectorAgent?.name;
    const location = useLocation();
    const dispatch = useDispatch();
    const features = useSelector(state => ({
        byKey: state.features.byKey,
        active: state.features.active
    }), shallowEqual);
    
    const connectorIdFromLocation = location.state?.connectorId;

    initializationData.current = {
        userMetadata,
        ...location.state,
        accessToken: isThirdParty ? thirdPartyToken : accessToken,
        accountId: accountId === "no-project" ? undefined : accountId,
        workspaceId,
        organizationId: organizationId || undefined,
        scopeId: scopeId || undefined,
        connectorId: connectorIdFromLocation || connectorId || undefined,
        isDemoMode: isDemoMode || false,
        pathname: location.pathname,
        hash: location.hash,
        search: location.search,
        domain: window.location.host,
        features,
        subTabs,
        featureId,
        connectorName: connectorName
    };

    const sendMessage = useCallback(({ type, payload }) => {
        if (iframeRef.current && isReadyRef.current) {
            iframeRef.current.contentWindow.postMessage({
                type: `SERVICE:${type}`,
                payload
            }, iframeOrigin);
        }
    }, [iframeRef, iframeOrigin]);

    useEffect(() => {
        if (shouldListen) {
            const receiveMessage = (event) => {
                if (!iframeRef.current || event.origin !== iframeOrigin || event.source !== iframeRef.current.contentWindow) {
                    return;
                } else {
                    if (_.isObject(event.data)) {
                        const { type, payload } = event.data;

                        switch (type) {
                            case "SERVICE:READY": {
                                setReadyState(uuidv4());
                                isReadyRef.current = true;
                                return;
                            }
                            case "SERVICE:NOTIFICATION": {
                                dispatch({
                                    type: payload.type,
                                    payload: {
                                        message: payload.message,
                                        exception: payload.exception,
                                        group: payload.group,
                                        key:payload.key
                                    }
                                });
                            }
                            case "SERVICE:NAVIGATE": {
                                if (payload.hash) {
                                    history.replace({
                                        ...history.location,
                                        hash: payload.hash
                                    })
                                } else {
                                    history.push(payload.pathname, {
                                        ...payload.state,
                                        fromService: "-"
                                    })
                                }
                                return;
                            }
                            case "SERVICE:ADD-NSS": {
                                dispatch({
                                    type: "SUPPORT:ADD-NSS-OPEN-POPOVER",
                                    track: `from:${trackKey}`,
                                    payload
                                })
                                return;
                            }
                            default: {
                                const matched = listener(event.data);
                                if (!matched) {
                                    console.log(`Parent APP received unknown message type - ${type}`);
                                }
                                return;
                            }
                        }
                    }
                }
            };

            window.addEventListener("message", receiveMessage, false);

            return () => {
                window.removeEventListener("message", receiveMessage)
            }
        }
    }, [iframeOrigin, history, listener, shouldListen, dispatch, trackKey, sendMessage]);

    useEffect(() => {
        if (readyState) {
            sendMessage({
                type: "ON-READY",
                payload: initializationData.current
            })
        }
    }, [sendMessage, readyState])

    useEffect(() => {
        sendMessage({
            type: "CONNECTOR-CHANGE",
            payload: {
                connectorId,
                connectorName
            }
        })
    }, [sendMessage, connectorId, connectorName]);

    useEffect(() => {
        sendMessage({
            type: "WORKSPACE-CHANGE",
            payload: {
                workspaceId,
                scopeId
            }
        })
    }, [sendMessage, workspaceId]);

    useEffect(() => {
        sendMessage({
            type: "TOKEN-UPDATE",
            payload: {
                accessToken,
                userMetadata
            }
        })
    }, [sendMessage, accessToken, userMetadata]);

    useEffect(() => {
        if (apiResponses.length > 0) {
            const messageGroups = _.groupBy(apiResponses, "type");

            if (messageGroups["NSS-ADDED"]?.length > 0) {
                sendMessage({
                    type: "NSS-ADDED"
                })
            }

            if (messageGroups["ADD-NSS-FAILED"]?.length > 0) {
                sendMessage({
                    type: "ADD-NSS-FAILED"
                })
            }

            dispatch({
                type: 'OCCM-PROXY:RESPONSES-HANDLED', payload: apiResponses
            });
        }

    }, [sendMessage, apiResponses, dispatch]);

    const { pathname, hash, search } = location;
    useEffect(() => {
        sendMessage({
            type: "LOCATION-CHANGE",
            payload: { pathname, hash, search, featureId }
        })
    }, [sendMessage, pathname, hash, search, featureId]);

    const src = useMemo(() => {
        if (!shouldListen) {
            return null
        } else {
            return iframeUrl + (initializationData.current?.pathSuffix || '');
        }
    }, [iframeUrl, shouldListen]);

    return useMemo(() => {
        return { isInitialized: !!readyState, iframeRef, src }
    }, [readyState, src])
};
