export const unknownName = 'Unknown';

const createWorkingEnvironmentConfig = {
    getResourceName: ({payload}) => fetchResourceName(payload.params.requestData, 'name'),
    getLabels: ({payload}) => {
        if (payload.priority === "failed") {
            return {
                action: `Working Environment "${payload.params.resourceName}" failed to be created`
            }
        } else if (payload.priority === "success") {
            return {
                action: `Working Environment "${payload.params.resourceName}" created successfully`
            }
        }
    }
};

const createVolumeConfig = {
    getResourceName: ({payload}) => fetchResourceName(payload.params.requestData, 'name'),
    getLabels: ({payload}) => {
        if (payload.priority === "failed") {
            return {
                action: `Volume "${payload.params.resourceName}" failed to be created`
            }
        } else if (payload.priority === "success") {
            return {
                action: `Volume "${payload.params.resourceName}" created successfully`
            }
        }
    }
};

const createAggregateConfig = {
    getResourceName: ({payload}) => fetchResourceName(payload.params.requestData, 'name'),
    getLabels: ({payload}) => {
        if (payload.priority === "failed") {
            return {
                action: `Aggregate "${payload.params.resourceName}" failed to be created`
            }
        } else if (payload.priority === "success") {
            return {
                action: `Aggregate "${payload.params.resourceName}" created successfully`
            }
        }
    }
};

const deleteWorkingEnvironmentConfig = {
    getResourceName: ({payload}) => fetchResourceName(payload.params.requestData, 'name', 'workingEnvironmentName', 'workingEnvironmentId'),
    getLabels: ({payload}) => {
        let operation = "deleted"
        const isLocalDelete = fetchResourceName(payload.params.requestData, 'localDelete')
        if (isLocalDelete === "true"){
            operation = "removed"
        }
        if (payload.priority === "failed") {
            return {
                action: `Working Environment "${payload.params.resourceName}" failed to be ${operation}`
            }
        } else if (payload.priority === "success") {
            return {
                action: `Working Environment "${payload.params.resourceName}" was ${operation}`
            }
        }
    }
};

const deleteVolumeConfig = {
    getResourceName: ({payload}) => fetchResourceName(payload.params.requestData,'name', 'volumeName'),
    getLabels: ({payload}) => {
        if (payload.priority === "failed") {
            return {
                action: `Volume "${payload.params.resourceName}" failed to be deleted`
            }
        } else if (payload.priority === "success") {
            return {
                action: `Volume "${payload.params.resourceName}" was deleted`
            }
        }
    }
};

const deleteAggregateConfig = {
    getResourceName: ({payload}) => fetchResourceName(payload.params.requestData,'name', 'aggregateName'),
    getLabels: ({payload}) => {
        if (payload.priority === "failed") {
            return {
                action: `Aggregate "${payload.params.resourceName}" failed to be deleted`
            }
        } else if (payload.priority === "success") {
            return {
                action: `Aggregate "${payload.params.resourceName}" was deleted`
            }
        }
    }
};

const fetchResourceName = (requestData, ...fields) => {
    if(!requestData) {
        return unknownName;
    }

    try {
        const data = JSON.parse(requestData);
        const field = fields.find(field => data[field]);
        return data[field] || unknownName;
    }
    catch (error) {
        console.error('Failed to fetch resource name', error);
        return unknownName;
    }
};

export const refreshCanvas = {
    "cloud-manager": {
        "Create Vsa Working Environment": true,
        "Create Aws Ha Working Environment": true,
        "Create Azure Ha Working Environment": true,
        "Delete Vsa Working Environment": true
    }
}

export default {
    "cloud-manager": {
        "Create Vsa Working Environment": createWorkingEnvironmentConfig,
        "Create Aws Ha Working Environment": createWorkingEnvironmentConfig,
        "Create Azure Ha Working Environment": createWorkingEnvironmentConfig,
        "Create Volume": createVolumeConfig,
        "Create Aggregate": createAggregateConfig,
        "Delete Vsa Working Environment": deleteWorkingEnvironmentConfig,
        "Delete Volume": deleteVolumeConfig,
        "Delete Aggregate": deleteAggregateConfig
    }
}
