import { update, reject, isEmpty } from "lodash/fp";

const defaultState = () => ({
    byType: {},
    byTypeById: {}
});

export default (state = defaultState(), { type, originalType, requestId, request }) => {
    if (type.endsWith("-PENDING") && requestId) {
        const operation = { requestId, request }
        const id = request?.id;
        const updatedByType = update(["byType", originalType], operations => [...(operations || []), operation])(state);
        if (id) {
            return update(["byTypeById", originalType, id], operations => [...(operations || []), operation])(updatedByType);
        } else {
            return updatedByType;
        }
    } else if ((type.endsWith("-SUCCESS") || type.endsWith("-ERROR")) && requestId) {
        const id = request?.id;

        const updatedByType = update(["byType", originalType], operations => {
            const updated = reject({ requestId })(operations);
            return updated.length > 0 ? updated : undefined;
        })(state);

        return update(["byTypeById", originalType], byId => {
            if (byId) {
                const operations = byId[id];
                const updatedOperations = reject({ requestId })(operations);
                const updatedById = { ...byId };
                if (updatedOperations.length === 0) {
                    delete updatedById[id];
                } else {
                    updatedById[id] = updatedOperations;
                }
                return isEmpty(updatedById) ? undefined : updatedById;
            } else {
                return byId;
            }
        })(updatedByType);
    } else {
        return state;
    }
};
