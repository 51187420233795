import React from "react";
import { useSelector } from "react-redux";
import styles from "./MainHeader.module.scss";
import { Link } from 'react-router-dom';
import SettingsMenu from "./headerMenus/SettingsMenu";
import WorkspaceMenu from "./headerMenus/WorkspaceMenu";
import UserMenu from "./headerMenus/UserMenu";
import AccountMenu from "./headerMenus/AccountMenu";
import HelpMenu from "./headerMenus/HelpMenu";
import GlobalSearchWidget from "modules/global-search/components/GlobalSearchWidget";
import NotificationsMenu from "modules/notifications/components/NotificationsMenu"
import ConnectorMenu from "./headerMenus/ConnectorMenu";
import { Tag, PopoverContext } from "@netapp/bxp-design-system-react";
import { NetAppLogoIcon as NetAppLogo } from "@netapp/bxp-design-system-react/icons/netapp-services";
import OrganizationMenu from "./headerMenus/OrganizationMenu";
import ScopeMenu from "./headerMenus/ScopeMenu";
import { isNewTenancyEnabled } from "utils/localstorage";

export default React.memo(({focusLastNav}) => {
    const isDemo = useSelector(state => state.tenancy.isDemo);
    const { selectedAccountId, forbidden } = useSelector(state => state.tenancy);
    const userHasAccount = selectedAccountId && !forbidden;
    const isGlobalSearchOpen = useSelector(state => state.globalSearch?.isOpen);
    // Get the Feature flag state from Redux state.
    const isTenancyV4 = isNewTenancyEnabled();

    return (<PopoverContext Component="header" className={styles['container']}>
        <div className={styles["title"]}>
            <Link className={styles['main-link']} to="/"><NetAppLogo/>BlueXP</Link>
            {isDemo && <Tag level={12} color="chart-9" style={{ marginLeft: 22 }}>Demo</Tag>}
        </div>
        <a className={styles['skip-content']} href="#" onClick={focusLastNav}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    focusLastNav();
                }
            }}>Skip navigation</a>
        <div className={styles['menu-widgets']}>
            {userHasAccount && <>
                <GlobalSearchWidget/>
                {!isGlobalSearchOpen && <>
                    {/* Show Organization and Scope menu when Tenancy v4 flag is enabled else show existing Account and Workspace menu */}
                    { isTenancyV4 ? <OrganizationMenu/> : <AccountMenu/> }
                    { isTenancyV4 ? <ScopeMenu/> : <WorkspaceMenu/>}
                    <ConnectorMenu/>
                </>}
                {process.env.REACT_APP_IS_DARK !== "true" && <NotificationsMenu/>}
                <SettingsMenu/>
                <HelpMenu/>
            </>
            }
            <UserMenu/>
        </div>
    </PopoverContext>)
});
