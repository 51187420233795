export default ({ dispatch }) => next => action => {
    const r = next(action); // pass action immediately to prevent action status to be set in state prior to it being reduced based on the incoming action
    const { type } = action;
    const key = action.meta?.key;
    if (!!key) {
        const uniqueId = action.meta?.uniqueId;
        const actionKey = uniqueId ? `${key}:${uniqueId}` : key
        let status
        if (type.endsWith('-PENDING')) {
            status = 'PENDING'
        }
        else if (type.endsWith('-SUCCESS')) {
            status = 'SUCCESS'
        }
        else if (type.endsWith('-FAILURE')) {
            status = 'FAILURE'
        }
        if (status) {
            dispatch({type: 'ACTION-STATUS:SET-STATUS', payload: {status, key: actionKey}})
        }
    }

    return r;
}
