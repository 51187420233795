import app from "./reducers/appReducer";
import tenancy from "./reducers/tenancyReducer";
import auth from "./reducers/authReducer";
import menu from "./reducers/menuReducer";
import features from "./reducers/featuresReducer";
import notifications from "./reducers/notificationsReducer";
import actions from "./reducers/actionStatusReducer";
import occmProxy from "./reducers/occmProxyReducer";
import common from "./reducers/commonReducer";
import operations from "./reducers/operationsReducer";
import uiNotifications from "modules/notifications/reducer";
import modal from "./reducers/modalReducer";
import appMiddleware from "./middlewares/appMiddleware";
import authMiddleware from "./middlewares/authMiddleware";
import tenancyMiddleware from "./middlewares/tenancyMiddleware";
import taskPollerMiddleware from "./middlewares/taskPollerMiddleware";
import trackingMiddleware from "./middlewares/trackingMiddleware";
import notificationsMiddleware from "./middlewares/notificationsMiddleware";
import thunkMiddleware from "./middlewares/thunkMiddleware";
import actionStatusMiddleware from "./middlewares/actionStatusMiddleware";
import occmProxyMiddleware from "./middlewares/occmProxyMiddleware";
import commonMiddleware from "./middlewares/commonMiddleware";
import uiNotificationsMiddleware from "modules/notifications/middleware";
import userMiddleware from "./middlewares/userMiddleware";

import "./Layout.module.scss";

export const createStaticReducers = () => ({
    app,
    auth,
    tenancy,
    menu,
    features,
    notifications,
    actions,
    occmProxy,
    common,
    uiNotifications,
    operations,
    modal
});

export const createStaticMiddlewares = () => ([
    thunkMiddleware,
    appMiddleware,
    authMiddleware,
    tenancyMiddleware,
    taskPollerMiddleware,
    trackingMiddleware,
    notificationsMiddleware,
    actionStatusMiddleware,
    occmProxyMiddleware,
    commonMiddleware,
    uiNotificationsMiddleware,
    userMiddleware
]);


