import React, { useEffect } from "react";
import { getUnixTime } from 'date-fns'
import { useSelector } from "react-redux";
import externals from "../../../utils/externals";
import _ from "lodash";
import useCurrentService from "utils/useCurrentService"
import { apiClient } from 'utils/clients';

const style = window.document.createElement('style');
style.type = 'text/css';
window.document.head.appendChild(style);

const loadIntercom = _.once(() => {
    if (externals.intercomId) {
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', window.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args)
                };
                w.Intercom = i;

                function l() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + externals.intercomId;
                    var x = d.getElementsByTagName('script')[0];
                    setTimeout(function () {
                        x.parentNode.insertBefore(s, x)
                    }, 3000);
                }

                if(w.__loaded) {
                    l()
                } else {
                    if (w.attachEvent) {w.attachEvent('onload', l);} else {w.addEventListener('load', l, false);}
                }
            }
        })()
    } else {
        window.Intercom = () => {};
    }
});

const hide = () => {
    style.innerHTML = `.intercom-container, .intercom-lightweight-app, .intercom-app {
        display: none !important;
    }`;
};

const show = () => {
    style.innerHTML = ``;
};

export default React.memo(() => {
    const { email, userId, status, isAuthenticated, user_hash } = useSelector(state => state.auth);
    const appState = useSelector(state => state.app.appState);
    const accountSerial = useSelector(state => state.tenancy.selectedAccount?.accountSerial)
    const about = useSelector(state => state.currentConnector.about)
    const instancePlacement = useSelector(state => state.currentConnector.instancePlacement)
    const currentService = useCurrentService();
    const hideIntercom = _.get(currentService, "service.hideIntercom", false);

    useEffect(() => {
        if (appState !== "loading") {
            loadIntercom();
        }
    }, [appState]);

    useEffect(() => {
        if (hideIntercom) {
            hide();
        } else {
            show();
        }
    }, [hideIntercom]);

    useEffect(() => {
        (async () => {
            if ((status !== "PENDING" && status !== "UNSET") || appState === "proxy") {
                if (isAuthenticated && !email.includes("@netapp.com")) {
                    /**
                     * Get the user hash corresponding of the userId. 
                     */
                    const url = `${externals.platformBackend}/v1/NetApp.Identity/login-identities/generateHash`;
                    const user_hash_res = await apiClient.get(url);
                    /**
                     * Let's initialize the intercom even if the user_hash is empty, because in future if we want to disable the identity verification in intercom js, then we don't have to make any changes in the code again, with empty user_hash it should work. And when identity verification is enabled, if user_hash is not defined, the chat won't work.
                     */
                    window.Intercom('boot', {
                        app_id: externals.intercomId,
                        email: email,
                        user_id: userId,
                        created_at: getUnixTime(new Date()), //Returns Unix timestamp (in seconds)
                        user_hash: user_hash_res?.data?.userHash
                    });
                } else if (email && !email.includes("@netapp.com")) {
                    window.Intercom('boot', {
                        app_id: externals.intercomId
                    });
                }
            }
        })();
    }, [email, userId, status, isAuthenticated, appState]);

    useEffect(() => {
        if (about && instancePlacement && !email.includes("@netapp.com")) {
            window.Intercom('update', {
                "build": about.build,
                "beta": about.beta,
                "build_time": about.buildTimestamp,
                "version": about.version,
                "site": about.siteIdentifier?.site,
                "system_id": about.systemId,
                "install_location": instancePlacement?.installLocation
            })
        }
    }, [about, instancePlacement])

    useEffect(() => {
        if (accountSerial && !email.includes("@netapp.com")) {
            window.Intercom('update', {
                "Account Serial": accountSerial
            })
        }
    }, [accountSerial])

    return "";
});
