import { getItem, setItem } from "utils/localstorage";
import _ from "lodash";

const defaultState = () => ({
    favourites: {
        list: [],
        byKey: {}
    }
});

const favouritesItemKey = { itemKey: "menuFavourites", includeTenancyId: true };

export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "MENU:OPEN-WIDGET": {
            return { ...state, activeWidget: payload }
        }
        case "MENU:CLOSE-WIDGET": {
            return { ...state, activeWidget: null }
        }
        case "MENU:REMOVE-CONNECTOR": {
            const { agentId } = payload;
            return { ...state, removeConnector: agentId }
        }
        case "MENU:CANCEL-REMOVE-CONNECTOR": {
            return { ...state, removeConnector: null }
        }
        case "MENU:INITIALIZE": {
            const { activeFeatures, navJson, servicesByNewKey } = payload;

            const processCategories = navJson.items.map(category => {
                return {
                    ...category,
                    services: category.services
                        .filter(service => {
                            let rootFeature = null;
                            if(servicesByNewKey[service.key]?.uiIntegration?.features) {
                                rootFeature = _.find(servicesByNewKey[service.key].uiIntegration.features, { id: service.key + "/*"})?.id
                            }

                            return (service.key === "Platform.Canvas" || (rootFeature ? activeFeatures[rootFeature] : activeFeatures[servicesByNewKey[service.key].key])) && ((process.env.REACT_APP_IS_SAAS === "true" && service.suite !== "CloudManager") || service.suite === "CloudManager")
                        })
                        .map(service => {
                            const isExternal = service.suite !== "CloudManager";
                            return {
                                ...service,
                                subTabs: service.subTabs?.filter(subTab => {
                                    return process.env.REACT_APP_IS_DARK !== "true" && (!subTab.featureId || activeFeatures[subTab.featureId])
                                }),
                                isExternal,
                                href: isExternal && navJson.suites[service.suite]?.href,
                                category: category.label
                            }
                        })
                }
            })

            const filterCategories = processCategories.filter(category => category.services.length > 0);

            const servicesByTo = {};
            const servicesByKey = {};

            filterCategories.forEach(category => {
                category.services.forEach(service => {
                    servicesByTo[service.to] = service;
                    servicesByKey[service.key] = service;
                })
            });

            const favourites = {
                list: (getItem(favouritesItemKey) || []).filter(favourite => servicesByKey[favourite]),
                byKey: {}
            }
            favourites.list.forEach(favourite => favourites.byKey[favourite] = true);

            return { ...state, navItems: filterCategories, servicesByTo, servicesByKey, favourites }
        }
        case "MENU:SET-FAVOURITE": {
            const favourites = {
                list: [...state.favourites.list, payload.serviceId],
                byKey: { ...state.favourites.byKey, [payload.serviceId]: true }
            };

            setItem(favouritesItemKey, favourites.list);

            return { ...state, favourites }
        }
        case "MENU:UNSET-FAVOURITE": {
            const favourites = {
                list: state.favourites.list.filter(serviceId => serviceId !== payload.serviceId),
                byKey: { ...state.favourites.byKey, [payload.serviceId]: false }
            };

            setItem(favouritesItemKey, favourites.list);

            return { ...state, favourites }
        }
        default: {
            return state;
        }
    }
};
