import awsRegions from "../aws/regions";

import styles from "./Styles.module.scss";
import { AzureIcon, GcpIcon, AwsIcon, AwsCircleIcon, AzureCircleIcon, GcpCircleIcon, OnpremCircleIcon, OnpremIcon } from "@netapp/bxp-design-system-react/icons/custom";

const allRegions = {
    aws: awsRegions
}

export const regionLabel = (provider, regionOrLocation) => {
    return allRegions[provider]?.[regionOrLocation] || regionOrLocation;
}

export const providersMap = {
    aws: { label: "AWS", longLabel: "Amazon Web Services", icon: AwsIcon, circleIcon: AwsCircleIcon, tableIconClassName: styles['aws-icon'] },
    azure: { label: "Azure", longLabel: "Microsoft Azure", icon: AzureIcon, circleIcon: AzureCircleIcon, tableIconClassName: styles['azure-icon'] },
    gcp: { label: "Google Cloud", longLabel: "Google Cloud Platform", icon: GcpIcon, circleIcon: GcpCircleIcon, tableIconClassName: styles['gcp-icon'] },
    "on-prem": { label: "On-Premises", longLabel: "On-Premises", icon: OnpremIcon, circleIcon: OnpremCircleIcon, tableIconClassName: styles['on-prem-icon'] }
};
