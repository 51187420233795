import {trackEvent} from "utils/mrTracker";
import _ from "lodash";

const FLOW_ACTION = "FlowAction";

const parseType = (type, categorySuffix) => {
    let colonIndex = type.indexOf(":");
    if(colonIndex < 0) {
        colonIndex = type.indexOf("/");
    }

    return {
        category: _.upperFirst(_.camelCase(type.substring(0, colonIndex))) + (categorySuffix ? `:${categorySuffix}` : ""),
        action: `${FLOW_ACTION}:${_.upperFirst(_.camelCase(type.substring(colonIndex+1)))}`
    }
};

export default ({getState}) => next => async action => {
    const {type, payload, track, categorySuffix} = action;

    if(type === "TRACKING") {
        const {category, action, label: _label, value: _value, extract} = payload;

        const extracted = extract && extract(getState());
        const label = _label || extracted?.label;
        const value = _value || extracted?.value;

        trackEvent({category, action, label, value})
    } else if(track) {
        const {category, action} = parseType(type, categorySuffix);

        if (_.isFunction(track)) {
            const extracted = track(getState());
            if(extracted) {
                const {label, value} = extracted;
                trackEvent({category, action, label, value})
            }
        } else if(_.isObject(track)) {
            const {label, value} = track;
            trackEvent({category, action, label, value})
        } else if (_.isString(track)) {
            trackEvent({category, action, label: track})
        } else {
            trackEvent({category, action})
        }
    }


    return next(action);
}
