/**
 * This util file will have methods specific to accessibility.
 */

/**
 * This method allows you to focus on the first element within a specified container. By providing the container's reference, this hook enables you to easily focus on the initial element which has a tabindex value 0 or if it's "a" tag element or a "button" or an "input" element.
 * @param {*} containerToFocusRef: Reference of the container that you want to focus on its first element.
 * @param {*} lazilyLoaded: If there are containers that are lazily loaded and not immediately available in the DOM, you can pass the parameter value as true to indicate that we need to wait for some time until the container is loaded in the DOM.
 * @returns 
 */
export const focusContainerFirstEle = (containerToFocusRef = {}, lazilyLoaded = false) => {
    let interval = null;

    /**
     * query selector to focus on the initial element which has a tabindex value 0 or "a" tag element or a "button" or an "input" element. But if the input element is of type checkbox, we don't have to focus
     * the input element because there is a svg with tabindex 0 value which shows the checkbox, that will
     * be focussed.
     */
    let elementToFocusQuerySel = '[tabindex="0"], a:not([tabindex="-1"]), button:not([tabindex="-1"]), input:not([type="checkbox"]):not([tabindex="-1"])';
    if (lazilyLoaded) {
        /**
         * Let's wait for max 3s for the container to be loaded in to the dom. If it's still not loaded after 3s, let's clear the interval and return.
         */
        let intervalCount = 0;
        interval = setInterval(() => {
            if (intervalCount >= 30) {
                clearInterval(interval);
                return;
            }
            intervalCount++;
            const eleToFocus = containerToFocusRef?.current?.querySelector(elementToFocusQuerySel);
            if (eleToFocus) {
                /**
                 * If the element to focus is loaded in the DOM, clear the interval and focus the element.
                 */
                clearInterval(interval);
                eleToFocus.focus();
            }
        }, 100);
    } else {
        /**
         * If the containerToFocusRef element is not lazy loaded and is readily available on the DOM, focus the first focussable element.
         */
        containerToFocusRef?.current?.querySelector(elementToFocusQuerySel)?.focus();
    }
};