import React, { useState } from "react";
import { Modal, ModalHeader, ModalFooter, ModalContent, Button, Text, Heading } from "@netapp/bxp-design-system-react";
import { setItemAnon } from "utils/localstorage";
import { ReactComponent as Illustration1 } from "./illustration1.svg";
import { ReactComponent as Illustration2 } from "./illustration2.svg";
import { ReactComponent as Illustration3 } from "./illustration3.svg";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const infoGridCss = css`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-right: 75px;
`;

const illustrationCss = css`
  display: inline-flex;
  justify-content: center;
`;

export default React.memo(() => {
    const [isNavMovedClosed, setNavMovedClosed] = useState(false);

    const close = () => {
        setNavMovedClosed(true);
        setItemAnon({ itemKey: `dontShowNewBlue` }, true);
    };

    if (!isNavMovedClosed) {
        return <Modal style={{ width: 664 }}>
            <ModalHeader>
                Welcome to NetApp BlueXP
            </ModalHeader>
            <ModalContent>
                <Text style={{ marginBottom: 16 }}>Build, protect and govern your hybrid multi-cloud data estate from a single control plane.</Text>
                <div css={infoGridCss}>
                    <div css={illustrationCss}><Illustration1/></div>
                    <div>
                        <Heading level={3} style={{ marginBottom: 4 }}>Unified experience</Heading>
                        <Text>BlueXP allows you to manage all of your storage and data assets from a single interface. You can learn more about BlueXP on the BlueXP website.</Text>
                    </div>
                    <div css={illustrationCss}><Illustration2/></div>
                    <div>
                        <Heading level={3} style={{ marginBottom: 4 }}>Wide array of integrations</Heading>
                        <Text>In BlueXP, you’ll find many new storage services, as well as data services previously only available as a stand-alone service with a separate UI.</Text>
                    </div>
                    <div css={illustrationCss}><Illustration3/></div>
                    <div>
                        <Heading level={3} style={{ marginBottom: 4 }}>Intuitive new menu</Heading>
                        <Text>In BlueXP’s navigation menu, data services are organized by categories and are named according to their functionality. For example, “Cloud Sync” is now called “Data copy and sync” and can be found in the “Mobility” category. Hover tooltips provide more information.</Text>
                    </div>
                </div>
            </ModalContent>
            <ModalFooter>
                <Button onClick={close}>Continue</Button>
            </ModalFooter>
        </Modal>
    }
    return ""
});
