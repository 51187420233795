/**
 * Before sending to GA, remove id's from paths
 * If you want to keep a variable path segment, use * -> original value will be sent to GA
 * If you want to remove a variable path segment, use :someVariableName  -> variable name will be sent to GA
 */

const paths = [
    "/cvo-resources/:id",
    "/cvs/*/add-working-environment",
    "/cvs/*/:id",
    "/storage/*/:id/volumes/:svm/:volume",
    "/storage/*/:id",
    "/replication/setup/*/:sourceId/*/:targetId",
    "/replication/:source/:target",
    "/credentials/:cloudProviderAccount/*"
]

export default paths;
