const defaultState = () => ({});

export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "COMMON:FETCH-MY-IP-SUCCESS": {
            return {...state, myIp: payload.data?.ip }
        }
        case "COMMON:FETCH-MY-IP-ERROR": {
            return {...state, myIp: "0.0.0.0" }
        }
        default: {
            return state;
        }
    }
};
