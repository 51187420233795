import * as yup from 'yup';
import _ from "lodash"

yup.setLocale({
    mixed: {
        required: 'Mandatory field',
    },
    string: {
        matches: 'Field does not match the required pattern.'
    }
});

yup.addMethod(yup.array, "uniqueBy", function (message, mapPath, isCaseSensitive) {
    return this.test("uniqueBy", message, function (list) {
        const map = {};
        const foundIndex = _.findIndex(list, (item) => {
            let property = _.get(item, mapPath);

            if (isCaseSensitive) {
                property = (property || "").toLowerCase();
            }

            if (!map[property]) {
                map[property] = true
            } else {
                return true
            }
        })

        if (foundIndex > 0) {
            return this.createError({path: `${this.path}[${foundIndex}].${mapPath}`, message});
        } else {
            return true
        }
    });
});

yup.addMethod(yup.string, "uniqueWith", function (fields, message) {
    return this.test("uniqueWith", message, function (value) {
        if(!value) return true;

        for(const field of fields) {
            const siblingValue = this.parent[field];
            if(siblingValue && siblingValue === value) {
                return false;
            }
        }

        return true;
    });
});

yup.addMethod(yup.object, "extensionOrExtensions", function (message, extensionOrExtensions) {
    return this.test("extensionOrExtensions", message, function (file) {
        const extension = _.isArray(extensionOrExtensions) ? extensionOrExtensions : [extensionOrExtensions]
        if (!file || _.some(extension, extension => file.fileName?.endsWith(extension))) {
            return true
        } else {
            this.createError({ path: `${this.path}`, message });
        }
    });
});

yup.addMethod(yup.object, "fileSize", function (message, maxSizeInBytes) {
    return this.test("fileSize", message, function (file) {
        if (!file || file.contents?.length <= maxSizeInBytes) {
            return true
        } else {
            this.createError({path: `${this.path}`, message});
        }
    });
});

yup.addMethod(yup.string, "matchesField", function(field, message) {
    return this.test("matchesField", message, function(value) {
        return (!this.parent[field] && !value) || this.parent[field] === value
    })
})