import React from "react";
import classNames from "classnames";
import { Card } from "@netapp/bxp-design-system-react";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import {CloseMonochromeIconsIcon as Close} from "@netapp/bxp-design-system-react/icons/monochrome";
import {ReactComponent as PurpleMan} from "./purple-man.svg";
import styles from "./WeakConnectorBanner.module.scss"
import { useDispatch } from "react-redux";

export default React.memo(() => {
    const dispatch = useDispatch();

    return <Card className={styles.base}>
        <div className={styles.purple}>
            <PurpleMan/>
            <p className={classNames(typography.b, typography.body15)}>New services are now available!</p>
        </div>
        <div className={styles.text}>
            <button onClick={() => {
                dispatch({
                    type: "CURRENT-CONNECTOR:OPEN-WEAK-CONNECTOR-DIALOG",
                    track: "from-banner"
                })
            }}><p className={typography.b}>Click here to improve your BlueXP experience.</p></button>
            <button className={styles.close} onClick={() => {
                dispatch({
                    type: "CURRENT-CONNECTOR:CLOSE-WEAK-CONNECTOR-BANNER"
                })
            }}>
                <Close/>
            </button>
        </div>
    </Card>
});
