import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import dynamicMiddlewares,  { addMiddleware, removeMiddleware as _removeMiddleware } from 'redux-dynamic-middlewares';
import { createStaticReducers, createStaticMiddlewares } from "./modules/app/index";

export const history = createBrowserHistory();

const initialState = {};

const staticReducers = createStaticReducers();
const asyncReducers = {};

const createReducer  = () => {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    });
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(dynamicMiddlewares))
);

export const registerReducer = (key, asyncReducer) => {
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer())
};

export const removeReducer = (key) => {
    delete asyncReducers[key];
    store.replaceReducer(createReducer())
};

const namedMiddlewares = {};
export const registerMiddleware = (key, middleware) => {
    if(!middleware) {
        addMiddleware(key);
    } else {
        const existing = namedMiddlewares[key];
        if(existing) {
            _removeMiddleware(existing);
        }
        namedMiddlewares[key] = middleware;
        addMiddleware(middleware)
    }
};

export const removeMiddleware = (key) => {
    const middleware = namedMiddlewares[key];
    delete namedMiddlewares[key];
    _removeMiddleware(middleware);
};

addMiddleware(...createStaticMiddlewares());

const registeredModules = {};
export const registerModule = (name) => {
    registeredModules[name] = true;
}

export const removeModule = (name) => {
    registeredModules[name] = false;
}

export const isModuleRegistered = (name) => {
    return registeredModules[name] || false;
}

export default store;