import {actionStatus} from "../../../utils/actionStatus";
const defaultState = () => ({});

export default (state= defaultState(), { type, payload }) => {
    switch (type) {
        case "ACTION-STATUS:SET-STATUS": {
            const {key, status} = payload;
            const resolved = state[key]?.resolved || status !== actionStatus.PENDING;
            return {...state, [key]: {status, resolved}};
        }
        default: {
            return state;
        }
    }
};

