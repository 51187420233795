import _ from "lodash";
import {getUserIdForGoogleAnalytics} from "cloud-central-utils/encryption";
import {aggregatePath, trackPageView, trackEvent} from "../mrTracker";
import {history} from "../../store";
import querystring from "query-string";
import { get } from "lodash";

export const trackGaBeforeLogin = () => {
    if(process.env.REACT_APP_IS_SAAS !== "true") {
        return;
    }

    const search = querystring.parse(window.location.search);
    const tagId = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG;

    if(search?.redirectFrom && search.redirectFrom === "spot" && search.spotOrgId) {
        window.gtag('config', tagId, {
            'page_location': window.origin + aggregatePath({location: window.location}),
            'send_page_view': false,
            'transport_type': 'beacon'
        });

        const label = search.spotOrgId;

        trackEvent({category: "Anon", action: `RedirectFrom:${search.redirectFrom}`, label})
    }
}

export const setDealTaleAnalytics = authResult => {
    //authResult.idTokenPayload['http://cloud.netapp.com/internal'] - after testing dont add also internal
    if(process.env.REACT_APP_IS_SAAS !== "true" || process.env.REACT_APP_USE_DEALTALE !== "true" || authResult.idTokenPayload['http://cloud.netapp.com/internal'] === "NetAppTest") {
        return;
    }

    if (authResult.idTokenPayload && window.dtl) {
        window.dtl.identifyUser(authResult.idTokenPayload.sub, authResult.idTokenPayload.email, authResult.idTokenPayload.name)
        window.dtl.pageView();
        history.listen((location) => {
            window.dtl.pageView();
        });
    }
}

export const setFullStory = authResult => {
    if(process.env.REACT_APP_IS_SAAS !== "true") {
        return;
    }
    if(process.env.REACT_APP_USE_FULLSTORY === "true" && authResult.idTokenPayload){
        setFullStoryUserValues({
            "internal": authResult.idTokenPayload['http://cloud.netapp.com/internal'],
            "isNetappDomain": get(authResult, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email','').toLowerCase().endsWith("netapp.com")
        })
    }
}

export const setGoogleAnalytics = authResult => {
    if(process.env.REACT_APP_IS_SAAS !== "true") {
        return;
    }

    const tagId = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG;
    const idTokenPayload = authResult.idTokenPayload;

    if (idTokenPayload) {
        getUserIdForGoogleAnalytics(idTokenPayload.sub)
            .then(userId => {
                window.gtag('set', {'user_id': userId});
            });

        //Internal account taken from auth0
        const disableGAForInternalAccounts = ['NetAppTest', 'NetAppCourse', 'NetAppDemo'];
        const idTokenPayloadInternal = idTokenPayload['http://cloud.netapp.com/internal'];
        if (disableGAForInternalAccounts.includes(idTokenPayloadInternal)) {
            console.log(`Analytics Disabled for ${idTokenPayloadInternal} Account`);
            window[`ga-disable-${tagId}`] = true;
        } else {
            window.gtag('config', tagId, {
                'page_location': window.origin + aggregatePath({location: window.location}),
                'send_page_view': false
            });
            if (idTokenPayloadInternal === 'NetApp') {
                //verified netapp email or netapp federation
                //federations: NetAppSAML || TestSamlConn || netapp-waad || azuread || BlueXP-Federation;
                window.gtag('set', 'user_properties', {internalUser: 'YES'});
            } else {
                const email = idTokenPayload.email || 
                    _.get(authResult, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email');
                if (email && email.endsWith('@netapp.com')) {
                    //unverified netapp email
                    window.gtag('set', 'user_properties', {internalUser: 'UNKNOWN'});
                }
                else {
                    window.gtag('set', 'user_properties', {internalUser: 'NO'});
                }
            }
        }
    }
};

export const setGaAccount = (accountId) => {
    window.gtag('set', 'user_properties', {accountIdentity: accountId});
    trackPageView({ location: window.location });
    history.listen((location) => {
        trackPageView({ location });
    });
}

export const setFullStoryUserValues = (values) => {
    if(process.env.REACT_APP_USE_FULLSTORY === "true"){
        try{
            window["FS"].setUserVars(values);
        } catch(e) {
            console.log("Failed to set full story values");
            console.log(values);
            console.log(e);
        }

    }
}
